.is_modal [ng-click] {
  cursor: pointer;
}
.is_modal .modal-dialog {
  width: 1004px !important;
  left: 0 !important;
  margin-top: 120px;
  min-width: 1004px;
}
.is_modal .modal-dialog .white-header-title {
  font-weight: 100 !important;
  color: #60819D;
}
.is_modal .modal-dialog .white-header-subtitle {
  font-weight: 100 !important;
  color: #60819D;
}
.is_modal .modal-header {
  background-color: #4C7192;
  color: white;
  padding-left: 30px;
}
.is_modal .modal-header .modal-title {
  font-size: 20px;
  font-weight: 100;
}
.is_modal .modal-content {
  border-radius: 0;
  background-color: #EFEFF0;
  position: relative;
}
.is_modal .modal-content .has-error label {
  color: #a94442 !important;
}
.is_modal .modal-content label {
  color: #B1C0BF;
}
.is_modal .answer-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #FFF;
  color: #6484A0;
}
.is_modal .answer-box:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.is_modal .answer-box:hover {
  background-color: #293A52;
  color: #FFFFFF;
}
.is_modal .answer-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
}
.is_modal .answer-content div {
  display: table;
  width: 100%;
  height: 100%;
}
.is_modal .answer-content span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 400;
}
.is_modal .btn {
  width: 260px;
}
.is_modal .btn.important {
  background-color: #293A52 !important;
}
.is_modal .check-icon {
  margin-right: 15px;
  fill: #fff;
}
.is_modal .check-icon svg {
  width: 50px;
  height: 50px;
}
.is_modal .button-row {
  margin-bottom: 10px;
}
.is_modal input, .is_modal select, .is_modal textarea {
  color: #27547D;
  font-weight: 700;
}
.is_modal .radio label {
  color: #27547D;
}
.is_modal textarea {
  padding-left: 20px;
}
.is_modal [data-toggle=tooltip] {
  cursor: help;
}

.validation-invalid {
  color: #a94442;
}

.form-control.ng-invalid {
  border: 1px solid #a94442;
}

.modal.mandatory-info-modal .modal-dialog {
  width: 804px !important;
  min-width: 804px !important;
  max-width: 804px !important;
}
.modal.mandatory-info-modal .modal-dialog .modal-content {
  background-color: #F7F7F7;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-header.modal-header-blue {
  padding: 20px 30px !important;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-header.modal-header-blue p {
  font-weight: 500 !important;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-header.modal-header-blue p:first-child {
  font-weight: bold !important;
  font-size: 17px;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body {
  padding: 0 !important;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots {
  background-color: white;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container {
  height: 100px;
  margin: 0 auto;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul {
  position: relative;
  padding: 0;
  top: 33px;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul::after, .modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul::before {
  content: "";
  position: absolute;
  width: 50%;
  border: 1px solid #D8D8D8;
  height: 1px;
  top: 5.3px;
  z-index: 1;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul::after {
  left: 50%;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul li {
  z-index: 2;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 13px;
  border-radius: 50%;
  background: #DADADA;
  display: inline-block;
  color: white;
  position: absolute;
  box-shadow: 0 1px 2px gray;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul li::before {
  color: #71787F;
  top: 24px;
  position: relative;
  font-size: 13px;
  left: -43px;
  width: 100px;
  display: block;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul li.active {
  background: #00c2af;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul li.done {
  background: white url("/static/img/steps-done.png");
  width: 24px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  top: -6px;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots .progress-dots-container ul li.done::before {
  top: 29px;
  left: -40px;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-currency-owner .progress-dots-container {
  width: 400px;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-currency-owner .progress-dots-container ul li:nth-child(1)::before {
  content: "Tax Info";
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-currency-owner .progress-dots-container ul li:nth-child(2) {
  right: 50%;
  transform: translateX(50%);
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-currency-owner .progress-dots-container ul li:nth-child(2)::before {
  content: "Billing Info";
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-currency-owner .progress-dots-container ul li:last-child {
  right: 0;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-currency-owner .progress-dots-container ul li:last-child::before {
  content: "Bank Info";
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-issuing-partner .progress-dots-container {
  width: 275px;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-issuing-partner .progress-dots-container ul li:nth-child(1)::before {
  content: "Tax Info";
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-issuing-partner .progress-dots-container ul li:last-child {
  right: 0;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .progress-dots.progress-dots-issuing-partner .progress-dots-container ul li:last-child::before {
  content: "Billing Info";
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .form-container {
  margin: 0 auto;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .form-container .form-group {
  margin: 50px 0;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .form-container .form-group label {
  color: #003366;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .form-container .form-footer {
  text-align: center;
  margin-top: 50px;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .form-container .form-footer a {
  color: #2C3C54;
  text-decoration: underline;
}
.modal.mandatory-info-modal .modal-dialog .modal-content .modal-body .form-container .form-footer button {
  font-weight: 500 !important;
  width: 200px;
}

.modal-custom-backdrop-mandatory-info {
  display: none;
}

[state="initial-settings.company-form"] .modal-custom-backdrop-mandatory-info,
[state="initial-settings.billing-form"] .modal-custom-backdrop-mandatory-info,
[state="initial-settings.bank-form"] .modal-custom-backdrop-mandatory-info {
  display: block;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2049 !important;
  opacity: 0.5;
}

.tour-popup {
  position: absolute;
  z-index: 999999;
}
.tour-popup .tour-popup-container {
  position: relative;
}
.tour-popup .tour-popup-container .tour-popup-content {
  text-align: center;
  background-color: white;
  padding: 40px;
  max-width: 375px;
}
.tour-popup .tour-popup-container .tour-popup-content p {
  margin-top: 20px;
  font-size: 15.5px;
  text-align: justify;
}
.tour-popup .tour-popup-container .tour-popup-content button {
  margin-top: 20px;
  font-weight: 500 !important;
}
.tour-popup .tour-popup-container .arrow-top-right::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 50px solid white;
  border-left: 50px solid transparent;
  top: -49px;
  right: 20px;
}
.tour-popup .tour-popup-container .arrow-left-top::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 50px solid white;
  border-left: 50px solid transparent;
  top: 50px;
  left: -49px;
}
.tour-popup .tour-popup-container .arrow-left-bottom::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 50px solid white;
  border-left: 50px solid transparent;
  bottom: 50px;
  left: -49px;
}