.selling-members label {
  color: #003366;
  font-family: Lato;
  font-size: 14px;
}
.selling-members label.control-label {
  text-align: left !important;
}
.selling-members label p {
  margin-bottom: 0;
  text-align: left;
}
.selling-members .title {
  color: #4C7093;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.selling-members .subtitle {
  color: #003366;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
}
.selling-members .text {
  color: #71787F;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
}
.selling-members .allow-row {
  margin-top: 20px;
}
.selling-members button {
  background-color: #003366;
  font-weight: 100;
}
.selling-members .form-back-label {
  margin-top: 8px;
  margin-left: -20px;
  font-size: 14px;
  color: #003366;
}
.selling-members .form-back-input {
  margin-left: -20px;
}