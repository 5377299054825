#currency-table .modal-content {
  min-width: 950px;
}
#currency-table .modal-content .modal-body {
  max-height: 50vh;
  overflow-y: auto;
  padding-top: 0;
}
#currency-table .filter-row {
  background-color: #FFF;
  width: auto;
  padding: 5px;
  margin-left: 0;
  margin-right: 0;
}
#currency-table .filter-row label, #currency-table .filter-row .title {
  margin-top: 10px;
}
#currency-table col.col-debug {
  background-color: red;
}
#currency-table col.col-xs {
  width: 2.5%;
}
#currency-table col.col-sm {
  width: 5%;
}
#currency-table col.col-md {
  width: 15%;
}
#currency-table col.col-lg {
  width: 20%;
}
#currency-table .header-row .table {
  margin-bottom: 0;
}
#currency-table .header-row .table thead {
  background-color: #4C7093;
  color: #FFF;
}
#currency-table .header-row .table thead th {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
#currency-table .currency-table {
  text-align: center;
}
#currency-table .not-ready-button {
  padding-left: 35px;
}