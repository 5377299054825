#welcome .modal-body {
  padding: 0;
}
#welcome .modal-body .welcome-text {
  padding: 0 60px;
  margin-top: 95px;
}
#welcome .modal-body .welcome-text h1 {
  color: #00B49C;
  font-size: 40px;
}
#welcome .modal-body .welcome-text h2 {
  color: #4C7093;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 40px;
}
#welcome .modal-body .welcome-text p {
  color: #4C7093;
  font-size: 16px;
}
#welcome .modal-body .welcome-text .btn-start-tour {
  width: auto;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  font-weight: 500 !important;
}
#welcome .modal-body .welcome-text .btn-skip-tour {
  margin-top: 70px;
  margin-bottom: 20px;
  text-decoration: underline;
  color: #71787F;
}
#welcome .modal-body .welcome-image {
  height: 518px;
  background-repeat: no-repeat;
  background-size: cover;
}
#welcome .modal-body #welcome-image-issuing-partner {
  background-image: url("/static/img/background-welcome-issuing-partner.png");
}
#welcome .modal-body #welcome-image-currency-owner {
  background-image: url("/static/img/background-welcome-currency-owner.png");
}