.overview .ca-chart-panel .panel-body {
  height: 332px;
}
.overview .balance-title {
  margin-top: 9px;
}
.overview .balance-amount {
  margin-top: 10px;
}
.overview .balance-money {
  margin-top: 15px;
  margin-bottom: 0px;
}
.overview .legend-row {
  height: 35px;
  padding-top: 6px;
}
.overview .legend-row .ca-legend .ca-legend-item .ca-legend-color {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-right: 3px;
}
.overview .legend-row .ca-legend .ca-legend-item .ca-legend-color.position-1 {
  background-color: #003366;
}
.overview .legend-row .ca-legend .ca-legend-item .ca-legend-color.position-2 {
  background-color: #00B49C;
}
.overview .legend-row .ca-legend .ca-legend-item .ca-legend-color.position-3 {
  background-color: #F5A623;
}
.overview .legend-row .ca-legend .ca-legend-item .ca-legend-color.position-4 {
  background-color: #7ED321;
}
.overview .legend-row .ca-legend .ca-legend-item .ca-legend-color.position-5 {
  background-color: #4C7093;
}
.overview .legend-row .ca-legend .ca-legend-item .ca-legend-text {
  text-transform: capitalize;
  font-size: 14px;
  color: #003366;
}
.overview .time-filter-row {
  margin-top: 10px;
}
.overview .chart-row {
  margin-top: 10px;
}
.overview .chart-row .highcharts-credits {
  display: none;
}
.overview select.overview-select {
  color: #369 !important;
}
.overview .ca-message {
  text-align: center;
  border: 2px dashed #27547d;
  padding: 10px;
  margin-bottom: 20px;
}
.overview .ca-message p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.ca-chart-filter {
  width: 100%;
}
.ca-chart-filter .btn-default {
  border: 1px solid #DDDDDD;
  width: 16.6666%;
  border-radius: 0;
  padding: 10px 0;
  line-height: 0.5 !important;
  font-size: 11px;
}
.ca-chart-filter .btn-default.active {
  border-bottom: 1px solid #00beaf;
}
.ca-chart-filter .btn-default:last-child {
  width: 17.7%;
}

.ca-retail-filter {
  width: 100%;
  margin-top: -10px;
}
.ca-retail-filter .btn-default {
  border: 1px solid #DDDDDD;
  width: 50%;
  border-radius: 0;
  padding: 10px 0;
  text-transform: capitalize;
}
.ca-retail-filter .btn-default.active {
  border-bottom: 3px solid #00beaf;
}

#award-modal .ca-progress {
  margin-top: -17px;
  float: right !important;
}
#award-modal .input-award {
  display: inline;
}
#award-modal .input-award.input-main {
  width: 50%;
  margin-left: 15px;
}
#award-modal .input-award.input-narrow {
  width: 22%;
  margin-left: -4px;
}
#award-modal .btn-award {
  width: 10%;
  padding: 5px;
  padding-top: 6px;
  border-radius: 0;
  margin-bottom: 5px;
  border: 1px solid #c6d6df;
  margin-left: -4px;
  background-color: #FFF;
}
#award-modal .btn-award img {
  width: 33px;
}
#award-modal .ca-input-title-row {
  margin-bottom: 5px;
  padding-left: 17px;
}
#award-modal .ca-input-title-row .input-title {
  color: #003366;
  font-size: 12px;
}
#award-modal .ca-input-title-row .input-title.second-input-title {
  margin-left: 215px;
  display: inline-block;
}
#award-modal .ca-input-title-row .input-title img {
  width: 20px;
}
#award-modal .ca-input-subtitle-row {
  padding-left: 17px;
}
#award-modal .ca-input-subtitle-row .input-subtitle {
  color: #71787F;
  font-size: 12px;
}
#award-modal .ca-input-subtitle-row .input-subtitle.input-second-subtitle {
  margin-left: 138px;
}
#award-modal .tall-row {
  padding-top: 35px;
}

#award-done .ca-progress {
  margin-top: -17px;
  float: right !important;
}