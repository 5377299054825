#holder-question .modal-content {
  padding-bottom: 0;
}
#holder-question .centerDiv {
  max-width: 1000px;
  margin: 0 auto;
  height: 300px;
  text-align: center;
}
#holder-question .div1 {
  width: 33.333%;
  float: left;
  padding: 10px;
}
#holder-question .div2 {
  width: 33.333%;
  float: left;
  padding: 10px;
}
#holder-question .div3 {
  width: 33.333%;
  float: left;
  padding: 10px;
}