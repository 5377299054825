@tailwind preflight;
@tailwind components;
.border-black {
  @apply .pd-border .pd-border-black .pd-border-solid;
}

.pd-title {
  @apply pd-uppercase pd-text-blue-light pd-font-bold pd-text-base;
}

.pd-text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

a[class^=pd-m], span[class^=pd-m] {
  display: inline-block;
}

/*
<div class="pd-alert">
    <div>
    </div>
    <div>
    </div>
</div>
*/
.pd-alert {
  @apply pd-flex pd-bg-white pd-border pd-border-grey-light pd-p-20 pd-mb-20 pd-text-blue-dark;
}
.pd-alert > div:first-child {
  @apply pd-w-2/3 pd-text-14;
}
.pd-alert > div:last-child {
  @apply pd-w-1/3 pd-flex pd-items-center pd-justify-end;
}

a {
  @apply pd-text-green;
}
a[class^=btn-]:hover, a[class^=btn-]:focus {
  color: #FFF;
  text-decoration: none;
}

.pd-btn {
  @apply pd-border-none pd-rounded-full pd-text-14 pd-py-15 pd-px-40 pd-font-normal;
}
.pd-btn:focus {
  @apply pd-outline-none;
}
.pd-btn.pd-btn-blue-dark {
  @apply pd-text-white pd-bg-blue-dark;
}
.pd-btn.pd-btn-blue-dark:active {
  @apply pd-bg-blue-dark-active;
}
.pd-btn.pd-btn-blue-light {
  @apply pd-text-white pd-bg-blue-light;
}
.pd-btn.pd-btn-blue-light:active {
  @apply pd-bg-blue-light-active;
}
.pd-btn.pd-btn-green {
  @apply pd-text-white pd-bg-green;
}
.pd-btn.pd-btn-green:active {
  @apply pd-bg-green-active;
}
.pd-btn.pd-btn-grey {
  @apply pd-bg-grey-light pd-text-grey-dark;
}
.pd-btn.pd-btn-grey:active {
  background-color: #c1c1c1;
}
.pd-btn.pd-btn-sm {
  padding-top: 9px;
  padding-bottom: 9px;
  height: 36px;
  @apply pd-px-35 pd-text-13;
}
.pd-btn.pd-btn-xs {
  @apply pd-py-5;
  @apply pd-px-10;
  @apply pd-text-13;
}
.pd-btn[disabled] {
  @apply pd-bg-grey-light;
}

.btn-blue-dark {
  background-color: #2C3C54;
  color: white;
  outline: none;
  border-radius: 50px;
  padding: 7px 22px;
  font-weight: normal;
  font-size: 14px;
  border: none;
}
.btn-blue-dark:active {
  background-color: #1a2433;
}
.btn-blue-dark[disabled] {
  background-color: #DADADA;
  border-color: #DADADA;
  color: white;
  cursor: not-allowed;
}
.btn-blue-dark.btn-big {
  padding: 10px 40px;
  font-size: 15px;
}
.btn-blue-dark.btn-small {
  padding: 8px 9px;
  font-size: 12px;
}

.btn-blue-light {
  background-color: #4C7093;
  color: white;
  outline: none;
  border-radius: 50px;
  padding: 7px 22px;
  font-weight: normal;
  font-size: 14px;
  border: none;
}
.btn-blue-light:active {
  background-color: #3b5671;
}
.btn-blue-light[disabled] {
  background-color: #DADADA;
  border-color: #DADADA;
  color: white;
  cursor: not-allowed;
}
.btn-blue-light.btn-big {
  padding: 10px 40px;
  font-size: 15px;
}
.btn-blue-light.btn-small {
  padding: 8px 9px;
  font-size: 12px;
}

.btn-green {
  background-color: #00B49C;
  color: white;
  outline: none;
  border-radius: 50px;
  padding: 7px 22px;
  font-weight: normal;
  font-size: 14px;
  border: none;
}
.btn-green:active {
  background-color: #008170;
}
.btn-green[disabled] {
  background-color: #DADADA;
  border-color: #DADADA;
  color: white;
  cursor: not-allowed;
}
.btn-green.btn-big {
  padding: 10px 40px;
  font-size: 15px;
}
.btn-green.btn-small {
  padding: 8px 9px;
  font-size: 12px;
}

.btn-green-outline {
  background-color: white;
  color: #00B49C;
  outline: none;
  border-radius: 50px;
  padding: 7px 22px;
  font-weight: normal;
  font-size: 14px;
  border: 1px solid #00B49C;
}
.btn-green-outline:active {
  background-color: #008170;
  color: white;
}
.btn-green-outline[disabled] {
  background-color: #DADADA;
  border-color: #DADADA;
  color: white;
  cursor: not-allowed;
}
.btn-green-outline.btn-big {
  padding: 10px 40px;
  font-size: 15px;
}
.btn-green-outline.btn-small {
  padding: 8px 9px;
  font-size: 12px;
}

.btn-gray {
  background-color: #9B9B9B;
  color: white;
  outline: none;
  border-radius: 50px;
  padding: 7px 22px;
  font-weight: normal;
  font-size: 14px;
  border: none;
}
.btn-gray:active {
  background-color: #828282;
}
.btn-gray[disabled] {
  background-color: #DADADA;
  border-color: #DADADA;
  color: white;
  cursor: not-allowed;
}
.btn-gray.btn-big {
  padding: 10px 40px;
  font-size: 15px;
}
.btn-gray.btn-small {
  padding: 8px 9px;
  font-size: 12px;
}

input.pd-form-control, select.pd-form-control {
  @apply pd-inline-block pd-w-full pd-text-14 pd-text-blue pd-bg-white pd-border pd-border-grey-light pd-border-solid pd-outline-none pd-text-blue-light;
  color: #369;
  height: 35px;
  padding: 0 7px;
  line-height: 1.42857143;
  background-image: none;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
input.pd-form-control:focus, select.pd-form-control:focus {
  @apply pd-outline-none pd-border pd-border-blue-light pd-border-solid;
}
input.pd-form-control.pd-has-error, select.pd-form-control.pd-has-error {
  @apply pd-border pd-border-red-dark pd-border-solid;
}

.modal-body > .pd-row.pd-button-row {
  @apply pd-mt-40 pd-text-center pd-flex pd-flex-col pd-items-center;
}
.modal-body > .pd-row.pd-button-row > a {
  @apply pd-text-blue pd-text-14 pd-mt-15;
}

/*
<div class="pd-panel">
    <div class="pd-panel-header">
        <span class="pd-panel-title">title</span>
    </div>
    <div class="pd-panel-body">
        <div class="pd-panel-form-row">
            <div class="pd-panel-form-row-label">
                <p class="pd-panel-form-row-label-main">Main Label</p>
                <p class="pd-panel-form-row-label-secondary">Secondary label</p>
            </div>
            <div class="pd-panel-form-row-field">
                [Field]
            </div>
        </div>
    </div>
</div>
*/
.pd-panel {
  @apply pd-bg-white pd-py-35 pd-px-50 pd-shadow pd-mb-30 pd-text-14;
}
.pd-panel-header .pd-panel-title {
  @apply pd-text-base pd-text-blue-light pd-font-bold pd-uppercase;
}
.pd-panel-body {
  @apply pd-pt-30;
}
.pd-panel-body .pd-panel-form-row {
  @apply pd-flex pd-mb-30;
}
.pd-panel-body .pd-panel-form-row .pd-panel-form-row-label {
  width: 230px;
  @apply pd-mr-100;
}
.pd-panel-body .pd-panel-form-row .pd-panel-form-row-label-main {
  @apply pd-text-14 pd-text-blue pd-mb-5;
}
.pd-panel-body .pd-panel-form-row .pd-panel-form-row-label-secondary {
  @apply pd-text-13 pd-text-grey-dark;
}
.pd-panel-body .pd-panel-form-row .pd-panel-form-row-field {
  @apply pd-w-full pd-flex pd-text-14;
}
.pd-panel-body .pd-panel-form-row .pd-panel-form-row-field > div {
  @apply pd-px-10;
}

.ca-tag {
  background: #357194 !important;
  display: inline-block;
  border-radius: 3px;
  background: linear-gradient(to bottom, #f0f9ff 0%, #cbebff 47%, #a1dbff 100%);
  padding: 4px 5px;
  border: 1px solid #acacac;
  height: 26px;
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
}

table.ca-overview-table {
  font-size: 14px;
  background-color: transparent;
}
table.ca-overview-table tr {
  height: 30px;
}
table.ca-overview-table tr td {
  width: 400px;
}
table.ca-overview-table tr td:last-child > a:last-child {
  display: none;
}
table.ca-table-list {
  @apply .pd-shadow;
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
  font-weight: normal;
}
table.ca-table-list thead {
  width: 100%;
}
table.ca-table-list thead tr {
  border-bottom: 2px solid #4C7093;
}
table.ca-table-list thead tr th {
  text-transform: uppercase;
  font-size: 12px;
  height: 50px;
  vertical-align: middle !important;
  border-bottom: none !important;
  text-align: center;
}
table.ca-table-list thead tr th:first-child {
  border-left: transparent;
  padding-left: 20px;
}
table.ca-table-list thead tr th:last-child {
  border-right: transparent;
  padding-right: 20px;
}
table.ca-table-list tbody td {
  text-transform: capitalize;
  font-size: 14px;
  height: 50px;
  vertical-align: middle !important;
  text-align: center;
}
table.ca-table-list tbody td:first-child {
  border-left: transparent;
  padding-left: 20px;
}
table.ca-table-list tbody td:last-child {
  border-right: transparent;
  padding-right: 20px;
}
table.ca-table-list tbody td .onoffswitch {
  display: inline-block;
}
table.ca-table-list .request-declined {
  opacity: 0.5;
}
table.pd-table-detail {
  @apply pd-w-full;
}
table.pd-table-detail tr th {
  @apply pd-bg-blue-light pd-text-white pd-px-10 pd-text-14 pd-w-1/2 pd-border-b pd-border-white pd-font-normal;
  height: 60px;
  max-height: 60px;
}
table.pd-table-detail tr td {
  @apply pd-px-10 pd-text-14 pd-w-1/2 pd-border pd-border-grey-light;
  height: 60px;
  max-height: 60px;
}

textarea.form-control {
  color: #369;
}

.vertical-separator {
  @apply .pd-border-0;
  @apply .pd-border-l;
  @apply .pd-border-solid;
  @apply .pd-border-grey-light;
  @apply .pd-ml-10;
  @apply .pd-mr-10;
}

/*
<div class="pd-widget">
    <div class="pd-widget-header"></div>
    <div class="pd-widget-body"></div>
    <div class="pd-widget-footer"></div>
</div>
*/
.pd-widget {
  @apply pd-border-grey-light pd-border pd-border-solid;
}
.pd-widget .pd-widget-header {
  @apply pd-bg-blue-light pd-text-center pd-text-white pd-py-20 pd-px-30;
}
.pd-widget .pd-widget-body {
  @apply pd-py-20 pd-px-30 pd-text-center;
}
.pd-widget .pd-widget-footer {
  @apply pd-border-t pd-border-solid pd-border-grey-light pd-py-20 pd-px-30;
}

.ca-filter-row-2 {
  @apply pd-flex pd-items-end pd-mb-20;
}
.ca-filter-row-2 .ca-spacer {
  display: inline-block;
  flex-grow: 1;
  background-color: red;
  height: 10px;
}

.ca-numbered-input-row {
  display: flex;
  font-weight: normal;
}
.ca-numbered-input-row > span:first-child {
  display: inline-block;
  background-color: #036;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50px;
  padding: 4px;
  color: #fff;
  font-size: 12px;
}
.ca-numbered-input-row > span:nth-child(2) {
  margin-left: 15px;
  display: inline-block;
  width: 380px;
  font-size: 14px;
}
.ca-numbered-input-row > div {
  width: 60%;
}

.pd-row {
  @apply pd-flex pd--mx-15;
}
.pd-row > div[class^=pd-w] {
  @apply pd-mx-15;
}

.ca-alert {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: lighter;
  font-size: 14px;
}
.ca-alert i {
  margin-top: 4px;
}
.ca-alert.danger {
  border: 1px solid #D0021B;
  background-color: white;
  color: #D0021B;
}
.ca-alert.danger-2 {
  border: 1px solid #D0021B;
  background-color: white;
  color: #9B9B9B;
}
.ca-alert.danger-2 .fa-exclamation-triangle {
  font-size: 34px;
  color: #D0021B;
  margin-right: 20px;
}
.ca-alert.info {
  background-color: #4C7093;
  color: white;
  text-align: center;
}
.ca-alert.info-2 {
  border: 2px dashed #27547d;
  text-align: center;
}
.ca-alert.info-2 .text {
  width: 100%;
}

.ca-back-header {
  @apply .pd-px-40;
  @apply .pd-py-20;
  @apply .pd-flex;
  @apply .pd-items-center;
  @apply .pd-border-0;
  @apply .pd-border-b;
  @apply .pd-border-solid;
  @apply .pd-border-grey-light;
  @apply .pd-bg-white;
  height: 100px;
  margin-left: -13px;
  margin-right: -13px;
}

.ca-back-header-with-message {
  @apply .pd-px-40;
  @apply .pd-py-20;
  @apply .pd-flex;
  @apply .pd-items-center;
  @apply .pd-bg-white;
  height: 100px;
  margin-left: -13px;
  margin-right: -13px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #dadada;
}
.ca-back-header-with-message .ca-message-show-hide {
  margin-right: 20px;
}
.ca-back-header-with-message .ca-message-show-hide .ca-link {
  text-decoration: none;
}
.ca-back-header-with-message .ca-message-show-hide .ca-link img {
  height: 35px;
}

[state="dashboard.send-issuing-partnership-request.company-details"] .ca-back-header-with-message,
[state="dashboard.send-issuing-partnership-request.partnership-conditions"] .ca-back-header-with-message,
[state="dashboard.send-issuing-partnership-request.issuing-partnership-details"] .ca-back-header-with-message {
  @apply .pd-border-0;
}

.ca-bell-alert {
  display: inline-block;
  color: #D0021B;
  cursor: context-menu;
}

.ca-checkbox .control {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}
.ca-checkbox .control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.ca-checkbox .control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  border: 1px solid #c6d6df;
}
.ca-checkbox .control-radio .control_indicator {
  border-radius: 0;
}
.ca-checkbox .control:hover input ~ .control_indicator,
.ca-checkbox .control input:focus ~ .control_indicator {
  background: #FFFFFF;
}
.ca-checkbox .control input:checked ~ .control_indicator {
  background: #FFFFFF;
}
.ca-checkbox .control:hover input:not([disabled]):checked ~ .control_indicator,
.ca-checkbox .control input:checked:focus ~ .control_indicator {
  background: #FFFFFF;
}
.ca-checkbox .control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.ca-checkbox .control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.ca-checkbox .control input:checked ~ .control_indicator:after {
  display: block;
}
.ca-checkbox .control-checkbox .control_indicator:after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid #54B9AC;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.ca-checkbox .control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #59BBAE;
}

.ca-close img {
  width: 15px;
}

.ca-excerpt {
  text-transform: initial;
}
.ca-excerpt p {
  margin: 0 !important;
}
.ca-excerpt a.excerpt-link {
  display: block;
  margin-top: 10px;
}

.ca-main-bank-selector .pd-radio-select {
  @apply pd-bg-white pd-border pd-border-grey-light pd-border-solid pd-p-20 pd-w-full pd-flex pd-items-center pd-cursor-pointer;
}
.ca-main-bank-selector .pd-radio-select:hover {
  @apply pd-bg-grey-light;
}

.ca-add-files-modal .modal-dialog {
  width: 800px !important;
}
.ca-add-files-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px;
}
.ca-add-files-modal .modal-dialog .modal-content .modal-body .uploading-state {
  text-align: center;
  margin: 50px 0;
  color: #00B49C;
  font-size: 15px;
  font-weight: bold;
}
.ca-add-files-modal .modal-dialog .modal-content .modal-body .uploading-state img {
  animation: spin 3s infinite linear;
}
.ca-add-files-modal .modal-dialog .modal-content .modal-body .uploading-state p {
  margin-top: 10px;
}
.ca-add-files-modal .modal-dialog .modal-content .modal-body .input-add-file {
  display: flex;
}
.ca-add-files-modal .modal-dialog .modal-content .modal-body .input-add-file .btn-file {
  height: 36px;
  width: 84px;
  background-color: #2C3C54;
  color: white;
  line-height: 33px;
  text-align: center;
  cursor: pointer;
}
.ca-add-files-modal .modal-dialog .modal-content .modal-body .input-add-file .label-info {
  border: 1px solid #c6d6df;
  border-right: none;
  color: #dadada;
  font-weight: lighter;
  background-color: white;
  margin: 0;
  padding: 0;
  height: 36px;
  width: 197px;
  padding: 6px;
  line-height: 22px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.ca-add-files-modal .modal-dialog .modal-content .modal-body .input-add-file .label-info.has-file {
  color: #369;
  font-weight: normal;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ca-add-url-modal .modal-dialog {
  width: 650px !important;
}
.ca-add-url-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px;
}

.ca-definitions-modal .modal-dialog {
  width: 600px !important;
}
.ca-definitions-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px;
}

.ca-partnership-status-modal .modal-dialog {
  width: 600px !important;
}
.ca-partnership-status-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px;
}
.ca-partnership-status-modal .modal-dialog .modal-content .modal-body p {
  margin-bottom: 10px;
}

.ca-escrow-accounts-info-modal .modal-dialog {
  width: 600px !important;
}
.ca-escrow-accounts-info-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px;
}

.ca-purchase-order-modal .modal-dialog .btn-make-payment:hover, .ca-purchase-order-modal .modal-dialog .btn-make-payment:active {
  color: white;
  text-decoration: none;
}
.ca-purchase-order-modal .modal-dialog .btn-make-payment .popover {
  color: #036;
}

.ca-redeem-automatic-calculator-modal .modal-dialog {
  width: 600px !important;
}
.ca-redeem-automatic-calculator-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 50px;
}
.ca-redeem-automatic-calculator-modal .modal-dialog .modal-content .modal-body table {
  font-size: 14px;
  font-weight: normal !important;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
}
.ca-redeem-automatic-calculator-modal .modal-dialog .modal-content .modal-body table tr {
  height: 45px;
}
.ca-redeem-automatic-calculator-modal .modal-dialog .modal-content .modal-body table tr td {
  border: 1px solid #ddd;
  font-weight: normal;
  padding: 8px !important;
}

div[id^=first-visit] .modal-dialog .modal-content .modal-header {
  background-color: #4C7093;
  padding: 20px 30px;
}
div[id^=first-visit] .modal-dialog .modal-content .modal-header .title {
  color: white;
}
div[id^=first-visit] .modal-dialog .modal-content .modal-body {
  text-align: left;
  padding: 50px 70px;
}
div[id^=first-visit] .modal-dialog .modal-content .modal-body h2 {
  margin-bottom: 30px;
  font-size: 32px;
}
div[id^=first-visit] .modal-dialog .modal-content .modal-body p {
  font-size: 16px;
  color: #4C7093;
  margin-bottom: 20px;
}

.ca-non-field-errors {
  color: #a94442;
  font-size: 11px;
}

.ca-notification {
  width: 100%;
  background-color: #4C7093;
  color: #fff;
  padding: 15px 30px;
  font-weight: lighter;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  margin-bottom: 10px;
  padding-bottom: 20px;
  align-items: center;
}
.ca-notification .fa-times {
  margin-top: -11px;
  margin-right: -22px;
}

.ca-danger-notification {
  padding: 15px 20px;
  border: 1px solid #D0021B;
  color: #D0021B;
  font-weight: lighter;
  font-size: 15px;
}

.ca-partner-logo-img img {
  width: 50px;
  height: 50px;
  max-width: 50px;
}

.ca-user-logo-img img {
  width: 50px;
  height: 50px;
  max-width: 50px;
  object-fit: contain;
}

.ca-switch .onoffswitch {
  text-align: left;
  display: inline-block;
}
.ca-switch .onoffswitch:focus {
  outline: none;
}
.ca-switch .onoffswitch-label {
  height: 24px !important;
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: none !important;
  border-radius: 12px !important;
  margin-bottom: 0 !important;
}
.ca-switch .onoffswitch-label:focus {
  outline: none;
}
.ca-switch .onoffswitch-switch {
  border: none !important;
  right: 30px;
  margin-right: 3px;
}
.ca-switch .onoffswitch-inner::after, .ca-switch .onoffswitch-inner::before {
  height: 24px !important;
  line-height: 24px !important;
}
.ca-switch .onoffswitch-inner::after {
  background-color: #eeeff1;
  color: #aeb9c3;
}
.ca-switch .onoffswitch-switch {
  top: 3px;
  height: 18px;
  bottom: auto;
  border-radius: 10px;
}
.ca-switch .onoffswitch [disabled] + .onoffswitch-label .onoffswitch-inner::before {
  background-color: #79e1cc;
  color: white;
}

.ca-tabs-nav {
  display: flex;
}
.ca-tabs-nav .tab-item {
  flex-grow: 1;
  flex-basis: 0;
  background-color: white;
  color: #003366;
  flex-grow: 1;
  text-align: center;
  font-size: 14px;
  padding: 7px 1px;
  border: 1px solid #C6D5DF;
  margin-right: -1px;
  cursor: pointer;
}
.ca-tabs-nav .tab-item.active {
  background-color: #2C3C54;
  color: white;
}

.ca-top-alert {
  background-color: #D0021B;
  position: absolute;
  color: white;
  top: 0;
  width: calc(100vw - 242px);
  left: 0;
  padding: 15px;
  z-index: 1;
  font-weight: lighter;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
}
.ca-top-alert a {
  color: white;
}

.ca-transaction-person-2 {
  text-transform: initial;
}

.ca-url-to-file {
  text-transform: initial;
}

.ca-select {
  width: 100%;
  min-width: 133px;
  display: inline-block;
}
.ca-select .ca-select-label {
  font-size: 14px;
  color: #8ea9a9;
  font-weight: lighter !important;
  margin-bottom: 10px;
  display: inline-block;
  text-transform: capitalize;
}
.ca-select select {
  width: 100%;
}

[class^=col-]:not(.col-xs-12) .ca-select {
  width: 100% !important;
  min-width: 100%;
}

.ca-multiselect {
  width: 100%;
  max-width: 207px;
}
.ca-multiselect .row .col-xs-6:first-child {
  padding-right: 2px;
}
.ca-multiselect .row .col-xs-6:last-child {
  padding-left: 2px;
}
.ca-multiselect .selector {
  height: 36px;
  outline: none;
  padding: 7px;
  border: 1px solid #C6D5DF;
  font-weight: lighter;
  color: #369;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.ca-multiselect .selector span {
  @apply truncate;
  display: inline-block;
  width: 90%;
}
.ca-multiselect .selector i {
  display: inline-block;
  position: relative;
  top: 3px;
  left: 0px;
}
.ca-multiselect .selector.has-error {
  border-color: #a94442;
}
.ca-multiselect .multiselect-main-list {
  -webkit-padding-start: 0;
  padding-left: 0;
  padding-bottom: 10px;
  list-style: none;
  max-height: 50vh;
  overflow-y: auto;
  margin-bottom: 0;
  background-color: #FFF;
  border: 1px solid #c6d5df;
  font-size: 14px;
  position: absolute;
  width: 115%;
  z-index: 9999;
}
.ca-multiselect .multiselect-main-list li {
  padding: 6px;
}
.ca-multiselect .multiselect-main-list li .control {
  top: 6px;
}
.ca-multiselect .multiselect-main-list li span {
  display: inline-block;
  margin-left: 33px;
}
.ca-multiselect .list {
  background-color: #fff;
  border-color: #fafafa;
  font-weight: normal;
  font-size: 14px;
  border: 1px solid #c6d5df;
  margin-top: 2px;
  position: absolute;
  overflow-y: auto;
  width: 136%;
  z-index: 9999;
}
.ca-multiselect .list input {
  margin-top: 2px;
  margin-left: 2px;
  width: 98%;
}
.ca-multiselect .list li {
  padding: 6px;
}
.ca-multiselect .list li .control {
  top: 6px;
}
.ca-multiselect .list li span {
  display: inline-block;
  margin-left: 33px;
}

.ca-from-to-filter {
  width: 225px;
}
.ca-from-to-filter .row .col-xs-6:first-child {
  padding-right: 2px;
}
.ca-from-to-filter .row .col-xs-6:last-child {
  padding-left: 2px;
}
.ca-from-to-filter .selector {
  height: 36px;
  outline: none;
  padding: 7px;
  border: 1px solid #C6D5DF;
  font-weight: lighter;
  color: #9eb0af;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
}
.ca-from-to-filter .selector.ca-dirty {
  color: #369;
  font-weight: 400;
  font-size: 14px;
}
.ca-from-to-filter .selector span {
  @apply truncate;
  display: inline-block;
  width: 90%;
}
.ca-from-to-filter .selector i {
  display: inline-block;
  position: relative;
  top: 3px;
  left: 0px;
}
.ca-from-to-filter .selector.has-error {
  border-color: #a94442;
}
.ca-from-to-filter .container-selects {
  -webkit-padding-start: 0;
  list-style: none;
  max-height: 50vh;
  margin-bottom: 0;
  background-color: #FFF;
  border: 1px solid #c6d5df;
  font-size: 14px;
  position: absolute;
  width: 100%;
  z-index: 1000;
  padding: 10px 10px;
  padding-top: 0;
}
.ca-from-to-filter .container-selects .select-from, .ca-from-to-filter .container-selects .select-to {
  margin-top: 15px;
}

.ca-custom-select {
  width: 100%;
  min-width: 136px;
  display: inline-block;
}
.ca-custom-select .ca-select-label {
  font-size: 14px;
  color: #8ea9a9;
  font-weight: lighter !important;
  margin-bottom: 10px;
  display: inline-block;
  text-transform: capitalize;
}
.ca-custom-select .select-hidden {
  visibility: hidden;
}
.ca-custom-select .select {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #036;
  width: 100%;
  height: 36px;
}
.ca-custom-select .select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  color: #369;
  border: 1px solid #c6d5df;
  padding: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ca-custom-select .select-styled i {
  position: relative;
  top: 3px;
}
.ca-custom-select .select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 1001;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  color: #036;
  border: 1px solid #c6d5df;
  max-height: 300px;
  overflow-y: auto;
}
.ca-custom-select .select-options li {
  margin: 0;
  padding: 7px 1px;
  text-indent: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ca-custom-select .select-options li.active {
  color: white;
  background: #2c3c54;
}
.ca-custom-select .select-options li:not(.active):hover {
  background-color: #eee;
}
.ca-custom-select .select-options li[rel=hide] {
  display: none;
}

.ca-sort-by-column.arrow-active .text-label-sort {
  text-decoration: underline;
}
.ca-sort-by-column.arrow-active .arrow-rotate {
  transform: rotate(180deg);
}
.ca-sort-by-column:hover .text-label-sort {
  text-decoration: underline;
}
.ca-sort-by-column .arrow-sort img {
  position: relative;
  top: -1px;
}

.ca-total-card {
  background-color: white;
  -webkit-box-shadow: 0 3px 5px -2px #adadad;
  -moz-box-shadow: 0 3px 5px -2px #adadad;
  box-shadow: 0 3px 5px -2px #adadad;
  padding: 12px 20px;
  height: 84px;
  position: relative;
}
.ca-total-card .text {
  color: #4C7093;
  font-size: 14px;
  line-height: 17px;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  float: right;
  margin-bottom: 8px;
}
.ca-total-card .num {
  font-size: 29px;
  text-align: right;
  font-weight: bold;
  line-height: 35px;
  color: #2C3C54;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ca-total-card .spinner {
  display: block;
  float: right;
  margin-top: 7px;
}
.ca-total-card .tooltip-container {
  position: absolute;
  top: 5px;
  left: 10px;
}

.ca-total-filters-container .control-checkbox {
  padding-top: 0;
}
.ca-total-filters-container .control-checkbox .show-totals-checkbox {
  font-size: 14px;
  font-weight: bold;
}
.ca-total-filters-container .animate-show-hide.ng-hide {
  height: 0;
}
.ca-total-filters-container .animate-show-hide.ng-hide-add,
.ca-total-filters-container .animate-show-hide.ng-hide-remove {
  transition: all linear 0.3s;
  overflow: hidden;
}
.ca-total-filters-container .checkbox-checked {
  height: 90px;
}

.ca-export-button button {
  outline: none;
  padding: 10px 30px !important;
}
.ca-export-button button img {
  width: 18px;
  margin-right: 5px;
}
.ca-export-button ul.dropdown-menu {
  z-index: 9999;
  padding: 0;
  margin-left: 2px;
}
.ca-export-button ul.dropdown-menu li {
  height: 100%;
}
.ca-export-button ul.dropdown-menu li a {
  color: rgb(0, 51, 102);
  font-size: 13px;
  margin: 0;
  padding: 12px 20px;
  border-radius: 0;
}
.ca-export-button ul.dropdown-menu li a:hover {
  background: #2c3c56;
  color: white;
  border-radius: 0;
}
.ca-export-button ul.dropdown-menu li.divider {
  margin: 0;
}

.first-steps {
  background-color: white;
  /* hide leaving slide  */
  /* show */
  /* hide */
  /* show entering slide  */
  /* hide */
  /* show */
}
.first-steps .wrapper {
  padding: 40px 10px 40px;
}
.first-steps .wrapper .first-steps-container {
  position: relative;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
}
.first-steps .wrapper .first-steps-container .first-steps-header {
  display: flex;
  background-color: #00B49C;
  color: white;
  padding: 15px 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.first-steps .wrapper .first-steps-container .first-steps-header .close {
  background: url("/static/img/cross-white.png");
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 18px;
  right: 30px;
}
.first-steps .wrapper .first-steps-container .first-steps-content {
  color: #4C7093;
  text-align: center;
  /* dots */
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots {
  height: 70px;
  background-color: white;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots ul {
  position: relative;
  padding: 0;
  height: 70px;
  padding-top: 25px;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots ul li {
  z-index: 2;
  width: 13px;
  height: 13px;
  text-align: center;
  line-height: 13px;
  border-radius: 50%;
  background: #DADADA;
  display: inline-block;
  color: white;
  position: absolute;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots ul li::before {
  content: attr(data-title);
  color: #71787F;
  font-size: 12px;
  width: 120px;
  display: block;
  text-align: center;
  left: -54px;
  position: absolute;
  top: 23px;
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-info {
  background-color: #FFF;
  padding: 32px;
  height: calc(100% - 46px);
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-info .btn {
  font-weight: 500 !important;
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer {
  display: flex;
  padding: 14px 32px;
  background-color: #F7F7F7;
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer > div {
  position: relative;
  width: 50%;
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-next {
  color: #00B49C;
  padding-right: 15px;
  float: right;
  cursor: pointer;
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-prev {
  color: #4C7093;
  padding-left: 15px;
  float: left;
  cursor: pointer;
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-next::before, .first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-next::after,
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-prev::before, .first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-prev::after {
  height: 0.7em;
  width: 0.13em;
  content: "";
  position: absolute;
  cursor: pointer;
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-next::before {
  background: #00B49C;
  top: 0.45em;
  right: 0;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-next::after {
  background: #00B49C;
  top: 0em;
  right: 0;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-prev::before {
  background: #4C7093;
  top: 0.45em;
  left: 0;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .arrow-prev::after {
  background: #4C7093;
  top: 0em;
  left: 0;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .disabled {
  color: #9B9B9B !important;
  cursor: not-allowed;
}
.first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .disabled::before, .first-steps .wrapper .first-steps-container .first-steps-content .first-steps-footer .disabled::after {
  background: #9B9B9B !important;
}
.first-steps .animate-switch-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-size: 50px 50px;
}
.first-steps .animate-switch {
  width: 100%;
  height: 100%;
}
.first-steps .animate-switch > div {
  height: 100%;
}
.first-steps .animate-switch.ng-animate {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
}
.first-steps .animate-switch.ng-leave {
  left: 0;
}
.first-steps .forward .animate-switch.ng-leave.ng-leave-active {
  left: -100%;
}
.first-steps .backward .animate-switch.ng-leave.ng-leave-active {
  left: 100%;
}
.first-steps .forward .animate-switch.ng-enter {
  left: 100%;
}
.first-steps .backward .animate-switch.ng-enter {
  left: -100%;
}
.first-steps .animate-switch.ng-enter.ng-enter-active {
  left: 0;
}

.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-currency-owner ul {
  margin: 0 250px;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-currency-owner ul li:nth-child(1) {
  left: 0;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-currency-owner ul li:nth-child(2) {
  left: 25%;
  transform: translateX(-50%);
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-currency-owner ul li:nth-child(3) {
  left: 50%;
  transform: translateX(-50%);
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-currency-owner ul li:nth-child(4) {
  left: 75%;
  transform: translateX(-50%);
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-currency-owner ul li:last-child {
  right: -2px;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-currency-owner ul li:last-child::after {
  content: none !important;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-currency-owner ul li::after {
  content: "";
  position: absolute;
  width: 115px;
  border: 1px solid #DADADA;
  height: 1px;
  top: 5.5px;
  z-index: 1;
  left: 100%;
}

.first-steps .first-steps-content[data-step=yourCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=yourCurrencyStepCO] .animate-switch-container {
  height: 300px;
}
.first-steps .first-steps-content[data-step=validatingCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=validatingCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=validatingCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=validatingCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=validatingCurrencyStepCO] .animate-switch-container {
  height: 275px;
}
.first-steps .first-steps-content.validatedCurrency .progress-dots.progress-dots-currency-owner ul li:nth-child(2) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content.validatedCurrency .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content.validatedCurrency .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=generateCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1), .first-steps .first-steps-content[data-step=generateCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=generateCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::before, .first-steps .first-steps-content[data-step=generateCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=generateCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::after, .first-steps .first-steps-content[data-step=generateCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=generateCurrencyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=generateCurrencyStepCO] .animate-switch-container {
  height: 305px;
}
.first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1), .first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2), .first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::before, .first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::before, .first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::after, .first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::after, .first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=apiStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(4) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=apiStepCO] .animate-switch-container {
  height: 370px;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1), .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2), .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3), .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(4) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::before, .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::before, .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3)::before, .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(4)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::after, .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::after, .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3)::after, .first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(4)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(5) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepCO] .animate-switch-container {
  height: 330px;
}
.first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1), .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2), .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3), .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(4), .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(5) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::before, .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::before, .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3)::before, .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(4)::before, .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(5)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(1)::after, .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(2)::after, .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(3)::after, .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(4)::after, .first-steps .first-steps-content[data-step=readyStepCO] .progress-dots.progress-dots-currency-owner ul li:nth-child(5)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=readyStepCO] .animate-switch-container {
  height: 300px;
}

.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-issuing-partner ul {
  margin: 0 250px;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-issuing-partner ul li:nth-child(1) {
  left: 0;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-issuing-partner ul li:nth-child(2) {
  left: 33%;
  transform: translateX(-50%);
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-issuing-partner ul li:nth-child(3) {
  left: 66%;
  transform: translateX(-50%);
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-issuing-partner ul li:last-child {
  right: -2px;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-issuing-partner ul li:last-child::after {
  content: none !important;
}
.first-steps .wrapper .first-steps-container .first-steps-content .progress-dots.progress-dots-issuing-partner ul li::after {
  content: "";
  position: absolute;
  width: 158px;
  border: 1px solid #DADADA;
  height: 1px;
  top: 5.5px;
  z-index: 1;
  left: 100%;
}

.first-steps .first-steps-content[data-step=yourPartnersStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=yourPartnersStepIP] .animate-switch-container {
  height: 315px;
}
.first-steps .first-steps-content[data-step=buyCurrencyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=buyCurrencyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=buyCurrencyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=buyCurrencyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=buyCurrencyStepIP] .animate-switch-container {
  height: 330px;
}
.first-steps .first-steps-content[data-step=apiStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1), .first-steps .first-steps-content[data-step=apiStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=apiStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1)::before, .first-steps .first-steps-content[data-step=apiStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=apiStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1)::after, .first-steps .first-steps-content[data-step=apiStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=apiStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(3) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=apiStepIP] .animate-switch-container {
  height: 370px;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1), .first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2), .first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(3) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1)::before, .first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2)::before, .first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(3)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1)::after, .first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2)::after, .first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(3)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(4) {
  background: #00c2af;
}
.first-steps .first-steps-content[data-step=loyaltyRulesStepIP] .animate-switch-container {
  height: 330px;
}
.first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1), .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2), .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(3), .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(4) {
  background: #fff url("/static/img/steps-done.png") no-repeat center;
  width: 25px;
  height: 24px;
  box-shadow: 0 0 0 6px white;
  height: 32px;
  margin-top: -10px;
  background-position-y: 6px;
}
.first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1)::before, .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2)::before, .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(3)::before, .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(4)::before {
  top: 36px;
  left: -48px;
}
.first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(1)::after, .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(2)::after, .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(3)::after, .first-steps .first-steps-content[data-step=readyStepIP] .progress-dots.progress-dots-issuing-partner ul li:nth-child(4)::after {
  top: 16px;
}
.first-steps .first-steps-content[data-step=readyStepIP] .animate-switch-container {
  height: 300px;
}

.ca-color-picker .ca-color-picker-box {
  width: 140px;
  border: 1px solid #C6D5DF;
  background-color: #FFFFFF;
  margin-right: 10px;
}
.ca-color-picker .ca-color-picker-color {
  width: 26px;
  height: 26px;
  margin: 5px;
  margin-right: 10px;
}
.ca-color-picker .ca-color-picker-change-btn {
  color: #00B49C;
  font-size: 14px;
  cursor: pointer;
  margin-left: 3px;
}
.ca-color-picker .ca-color-picker-change-btn:hover {
  text-decoration: underline;
}

.ca-picture-container-whitelabel {
  text-align: left;
}
.ca-picture-container-whitelabel .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 80px;
  height: 80px;
}
.ca-picture-container-whitelabel .img.round {
  border-radius: 200px;
}
.ca-picture-container-whitelabel .img.rectangle {
  width: 240px;
  height: 115px;
}
.ca-picture-container-whitelabel .img.favicon {
  width: 16px;
  height: 16px;
}
.ca-picture-container-whitelabel .img.wle-footer-image {
  width: 130px;
}
.ca-picture-container-whitelabel .img.wle-logo-image {
  width: 170px;
}
.ca-picture-container-whitelabel .img.wle-top-banner-image {
  width: 270px;
}
.ca-picture-container-whitelabel .img.wle-skyscraper-banner-image {
  height: 150px;
}
.ca-picture-container-whitelabel .img.wle-login-left-side-image {
  width: 160px;
  height: 228px;
}
.ca-picture-container-whitelabel .img-placeholder {
  border: 1px dashed #C6D5DF;
  background-color: #FFF;
  width: 80px;
  height: 80px;
}
.ca-picture-container-whitelabel .img-placeholder.round {
  border-radius: 200px;
}
.ca-picture-container-whitelabel .img-placeholder.rectangle {
  width: 240px;
  height: 115px;
}
.ca-picture-container-whitelabel .img-placeholder.favicon {
  width: 16px;
  height: 16px;
}
.ca-picture-container-whitelabel .img-placeholder.wle-footer-image {
  width: 130px;
  height: 48px;
}
.ca-picture-container-whitelabel .img-placeholder.wle-logo-image {
  width: 170px;
  height: 45px;
}
.ca-picture-container-whitelabel .img-placeholder.wle-top-banner-image {
  width: 270px;
  height: 40px;
}
.ca-picture-container-whitelabel .img-placeholder.wle-skyscraper-banner-image {
  height: 150px;
  width: 35px;
}
.ca-picture-container-whitelabel .img-placeholder.wle-login-left-side-image {
  width: 160px;
  height: 228px;
}

.ca-picture-container-btns .ca-link {
  display: flex;
  align-items: center;
}
.ca-picture-container-btns .ca-link:hover {
  text-decoration: underline !important;
}
.ca-picture-container-btns .ca-link img {
  margin-right: 5px;
}

.ca-form-row {
  margin-bottom: 20px;
}
.ca-form-row .field-col [class*=col-] {
  padding-right: 0;
}
.ca-form-row .field-col {
  font-size: 14px;
}
.ca-form-row .title {
  display: block;
  font-size: 14px;
  color: #4C7093;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.ca-form-row .subtitle {
  font-size: 13px;
  color: #71787F;
  font-weight: lighter;
  width: 100%;
  font-style: italic;
  display: inline-block;
}
.ca-form-row .input-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px;
  font-size: 14px;
}
.ca-form-row .input-row .control-radio {
  margin-bottom: 22px;
}
.ca-form-row .input-row input {
  width: 180px;
}
.ca-form-row .popover {
  width: 300px;
  color: #036;
}

.ca-accordion-item {
  border-bottom: 1px solid #dadada;
}
.ca-accordion-item.ca-accordion-item-open .ca-accordion-content {
  overflow: hidden;
}
.ca-accordion-item .ca-accordion-header {
  padding: 20px 0;
  cursor: pointer;
  width: 100%;
}
.ca-accordion-item .ca-accordion-header .ca-accordion-title {
  width: 95%;
  color: #003366;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.ca-accordion-item .ca-accordion-header img {
  float: right;
  position: relative;
  top: 5px;
}
.ca-accordion-item .ca-accordion-content {
  padding-bottom: 20px;
  display: none;
}

.ca-uploaded-items-container {
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
}
.ca-uploaded-items-container .ca-uploaded-item {
  border: 1px solid #C6D5DF;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 5px;
  background: white;
}
.ca-uploaded-items-container .ca-uploaded-item .icon-type {
  margin-left: 5px;
  margin-right: 10px;
}
.ca-uploaded-items-container .ca-uploaded-item .icon-type img {
  width: 15px;
}
.ca-uploaded-items-container .ca-uploaded-item .icon-delete {
  cursor: pointer;
  float: right;
  margin-right: 16px;
}

.ca-item-uploaded-list {
  max-height: 300px;
  overflow-y: auto;
}
.ca-item-uploaded-list .ca-item-uploaded {
  font-size: 14px;
  padding: 8px;
  padding-left: 0;
  display: flex;
  align-items: center;
}
.ca-item-uploaded-list .ca-item-uploaded:first-child {
  padding-top: 0;
}
.ca-item-uploaded-list .ca-item-uploaded .icon-type {
  margin-left: 5px;
  margin-right: 10px;
}
.ca-item-uploaded-list .ca-item-uploaded .icon-type img {
  width: 15px;
}

.ca-message-box {
  width: 375px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 99999;
  background: white;
  right: 0;
}
.ca-message-box .ca-message-box-header {
  padding: 20px 30px;
}
.ca-message-box .ca-message-box-header span {
  color: #4C7093;
  font-size: 18px;
  font-weight: bold;
}
.ca-message-box .ca-message-box-header img {
  float: right;
  width: 16px;
  margin-top: 4px;
}
.ca-message-box .ca-message-box-content {
  padding: 20px 30px;
  background: #F7F7F7;
}
.ca-message-box .ca-message-box-content .ca-message-box-messages {
  max-height: 250px;
  overflow-y: auto;
}
.ca-message-box .ca-message-box-content .ca-message-box-messages .ca-message-box-message .ca-message-box-message-title {
  color: #003366;
  font-size: 14px;
  font-weight: bold;
}
.ca-message-box .ca-message-box-content .ca-message-box-messages .ca-message-box-message .ca-message-box-message-date {
  color: #9B9B9B;
  font-size: 13px;
  font-style: italic;
  margin: 5px 0;
}
.ca-message-box .ca-message-box-content .ca-message-box-messages .ca-message-box-message .ca-message-box-message-content {
  color: #003366;
  font-size: 14px;
}
.ca-message-box .ca-message-box-content .ca-message-box-messages .ca-message-box-message hr {
  margin: 20px 0;
}
.ca-message-box .ca-message-box-content .ca-message-box-send {
  text-align: center;
}
.ca-message-box .ca-message-box-content .ca-message-box-send .ca-button {
  margin-top: 10px;
}

.badge-notify {
  background: #D0161B;
  position: relative;
  top: -17px;
  left: -10px;
  color: white;
  font-size: 12px;
  font-family: "Lato";
}

.ca-steps-progress {
  margin: 0 -15px;
  overflow: hidden;
}
.ca-steps-progress .arrow-steps {
  border: 1px solid #C6D5DF;
  border-left: none;
  border-right: none;
}
.ca-steps-progress .arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  padding: 15px 10px 15px 30px;
  float: left;
  position: relative;
  background-color: white;
}
.ca-steps-progress .arrow-steps .step:after,
.ca-steps-progress .arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -24px;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid white;
  z-index: 2;
}
.ca-steps-progress .arrow-steps .step:before {
  right: -26px;
}
.ca-steps-progress .arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 25px solid #C6D5DF;
  z-index: 0;
}
.ca-steps-progress .arrow-steps .step:first-child:before,
.ca-steps-progress .arrow-steps .step:last-child:after {
  border: none;
}
.ca-steps-progress .arrow-steps .step:last-child:after {
  background: white;
  height: 50px;
  display: block;
  right: -15px;
  width: 20px;
}
.ca-steps-progress .arrow-steps .step.active {
  background-color: #4C7093;
}
.ca-steps-progress .arrow-steps .step.active:after {
  border-left: 25px solid #4C7093;
}
.ca-steps-progress .arrow-steps .step span {
  color: #003366;
  font-weight: bold;
}
.ca-steps-progress .arrow-steps .step.active span {
  color: #fff;
}

.ca-overview-card {
  background-color: white;
  border: 1px solid #C6D5DF;
  padding: 15px;
  height: 100%;
}
.ca-overview-card .popover {
  width: 250px;
}

.ca-charts-container {
  margin-top: -35px;
  margin-bottom: 20px;
  border-bottom: 1px solid lightgray;
}

.ca-exchange-alert {
  @apply pd-bg-white pd-border pd-border-grey-light pd-p-20 pd-mb-20;
}

.ca-exchange-partnership-payment-term .special-feature-label {
  background-color: #2C3C54;
  color: #FFFFFF;
  font-size: 13px;
  font-style: italic;
  padding: 3px 13px;
  margin-left: 10px;
}
.ca-exchange-partnership-payment-term .recommended-label {
  background-color: #00B49C;
  color: #FFFFFF;
  font-size: 13px;
  font-style: italic;
  padding: 3px 13px;
  margin-left: 10px;
}
.ca-exchange-partnership-payment-term .label-small {
  font-size: 13px;
  color: #71787F;
  margin-left: 30px;
}

.ca-issuing-partnership-request-status .status-text {
  font-style: italic;
  padding: 0;
  text-transform: initial !important;
  color: gray;
}

.exchange-partnerships-settings .fa-info-circle {
  font-size: 15px;
}
.exchange-partnerships-settings tr {
  height: 55px !important;
}
.exchange-partnerships-settings tr[ca-table-row-spinner] {
  height: auto !important;
}

.ca-send-exchange-partnership-request .wrapper-content {
  margin-top: 10px;
}

.ca-send-exchange-partnership-request-company-details .ca-form-row {
  margin-bottom: 10px;
}

.ca-exchange-partnership-request-partnership-request {
  margin-top: 15px;
}
.ca-exchange-partnership-request-partnership-request .ca-form-row {
  margin-bottom: 10px;
}

.ca-exchange-partnership-details-company-information .ca-form-row {
  margin-bottom: 10px;
}

.ca-exchange-partnership-details-exchange-out .ca-form-row,
.ca-exchange-partnership-details-exchange-in .ca-form-row {
  margin-bottom: 10px;
}

.pd-price-row {
  @apply pd-flex pd-justify-start pd-items-center;
}
.pd-price-row ca-dot {
  width: 20px;
}
.pd-price-row label {
  width: 20px;
}
.pd-price-row div:nth-child(3) {
  width: 75px;
}
.pd-price-row div:nth-child(4) {
  width: 80px;
}
.pd-price-row div:nth-child(5) {
  @apply pd-ml-5;
}

.ca-exchange-partnership-definition-partnership-definition {
  margin-top: 20px;
}

.ca-exchange-partnership-edit-partnership-edit {
  margin-top: 20px;
}

.exchange-potential-partnerships table.ca-table-list tbody tr td,
.exchange-partnerships-active-partnerships table.ca-table-list tbody tr td {
  height: 53px;
}

.ca-search-2-input {
  @apply .pd-relative .pd-z-10;
}
.ca-search-2-input .results-dropdown {
  @apply .border-transparent .pd-flex .pd-justify-center .absolute .pd-w-full;
  top: 49px;
}
.ca-search-2-input .results-dropdown ul {
  @apply .pd-bg-white .pd-shadow .pd-list-reset;
  min-width: 99%;
  max-height: 135px;
  overflow-y: scroll;
}
.ca-search-2-input .results-dropdown ul li {
  @apply .pd-px-20 .pd-py-10 .pd-border-solid .pd-border-b .pd-border-t-0 .pd-border-l-0 .pd-border-r-0 .pd-border-grey-light .pd-cursor-pointer;
  white-space: nowrap;
}
.ca-search-2-input .results-dropdown ul li:hover {
  @apply .pd-bg-grey-lighter;
}

.ca-destination-member-switch hr {
  margin: 0;
  margin-top: 45px;
  margin-left: 15px;
}
.ca-destination-member-switch hr:before {
  content: "";
  position: absolute;
  bottom: 100%;
  border: 11px solid transparent;
  border-bottom-color: rgba(76, 112, 147, 0.3);
  top: 23px;
}
.ca-destination-member-switch hr:after {
  content: "";
  position: absolute;
  bottom: 100%;
  border: 11px solid transparent;
  border-bottom-color: #f3f3f4;
  top: 24px;
}
.ca-destination-member-switch hr.triangle-member:before, .ca-destination-member-switch hr.triangle-member:after {
  left: 72px;
}
.ca-destination-member-switch hr.triangle-bulk:before, .ca-destination-member-switch hr.triangle-bulk:after {
  left: 260px;
}

.ca-destination-bulk-type .valid-file-alert {
  border: 1px solid #C6D5DF;
  background-color: #FFFFFF;
  padding: 8px;
}
.ca-destination-bulk-type .valid-file-alert .close-cross {
  height: 15px;
  float: right;
  margin-top: 4px;
}

.ca-manual-transfer-bulk-details .ca-form-row {
  font-size: 14px;
}

.ca-my-members-list .balance-column {
  text-align: right;
}

.ca-issuing-partnership-payment-term .special-feature-label {
  background-color: #2C3C54;
  color: #FFFFFF;
  font-size: 13px;
  font-style: italic;
  padding: 3px 13px;
  margin-left: 10px;
}
.ca-issuing-partnership-payment-term .recommended-label {
  background-color: #00B49C;
  color: #FFFFFF;
  font-size: 13px;
  font-style: italic;
  padding: 3px 13px;
  margin-left: 10px;
}
.ca-issuing-partnership-payment-term .label-small {
  font-size: 13px;
  color: #71787F;
  margin-left: 30px;
}

.ca-exchange-partnership-request-status .status-text {
  font-style: italic;
  padding: 0;
  text-transform: initial !important;
  color: gray;
}

.ca-send-issuing-partnership-request .wrapper-content {
  margin-top: 10px;
}

.ca-send-issuing-partnership-request-company-details .ca-form-row {
  margin-bottom: 10px;
}

.ca-issuing-partnership-details-partnership-information .ca-form-row {
  margin-bottom: 10px;
}

.ca-issuing-partnership-details-company-information .ca-form-row {
  margin-bottom: 10px;
}

.ca-issuing-partnership-request-partnership-request {
  margin-top: 15px;
}
.ca-issuing-partnership-request-partnership-request .ca-form-row {
  margin-bottom: 10px;
}

.ca-issuing-partnership-definition-partnership-definition {
  margin-top: 20px;
}
.ca-issuing-partnership-definition-partnership-definition .ca-overdraft-options {
  margin-left: 260px;
  margin-bottom: 30px;
}

.ca-issuing-partnership-edit-partnership-edit {
  margin-top: 20px;
}
.ca-issuing-partnership-edit-partnership-edit .ca-overdraft-options {
  margin-left: 260px;
  margin-bottom: 30px;
}

.ca-table-list-requests .pd-text-left {
  text-align: left !important;
}

.issuing-partnerships .ca-custom-select {
  min-width: 150px;
}

.issuing-partnerships-requests-received .th-with-popover .popover,
.issuing-partnerships-requests-sent .th-with-popover .popover {
  max-width: 600px;
}
.issuing-partnerships-requests-received .th-with-popover .popover .popover-content,
.issuing-partnerships-requests-sent .th-with-popover .popover .popover-content {
  text-align: left;
}
.issuing-partnerships-requests-received .th-with-popover .popover .popover-content p,
.issuing-partnerships-requests-sent .th-with-popover .popover .popover-content p {
  margin-bottom: 5px;
}

.ca-escrow-limit {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #C6D5DF;
  padding-top: 15px;
  padding-bottom: 15px;
}
.ca-escrow-limit div {
  display: flex;
  justify-content: space-around;
  font-style: italic;
  font-size: 14px;
  color: #4C7093;
}
.ca-escrow-limit div span {
  width: 100px;
}

.check-row {
  display: flex;
  align-items: center;
}

.ca-giftcards-alert {
  border: 1px solid red;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
}
.ca-giftcards-alert i {
  font-size: 45px;
  color: red;
  width: 20%;
  text-align: center;
}
.ca-giftcards-alert .popover-content {
  width: 180px;
}

#disable-gift-cards-confirmation .modal-dialog {
  width: 450px !important;
}

.ca-gift-card-history .ca-filter-row-2 select {
  width: 100%;
}

.ca-custom-gift-cards .expiration_date .pd-form-control {
  display: initial;
  width: auto;
}
.ca-custom-gift-cards input[disabled] {
  cursor: not-allowed;
}

.ca-custom-gift-cards-form {
  margin-bottom: 40px;
}
.ca-custom-gift-cards-form .info-id-text {
  color: #9B9B9B;
  font-size: 12px;
  font-style: italic;
  line-height: 15px;
  margin-left: 10px;
  width: 100%;
}

.ca-issue-replacement-gift-card-modal .modal-dialog {
  width: 500px !important;
}
.ca-issue-replacement-gift-card-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px;
}

.other-currencies__purchases .ca-filter-row-2 select {
  width: 100%;
}

.ca-white-label-redemption-page-design .label-promoted-gift-cards {
  color: #4C7093;
  font-size: 14px;
  margin-bottom: 10px;
}

.ca-escrow-account-info-card {
  background-color: white;
  border: 1px solid #C6D5DF;
  padding: 20px;
  height: 100%;
  position: relative;
}

.ca-escrow-accounts-my-escrow-accounts table .table-header-primary-accounts {
  background-color: #2c3c54;
  color: #fff;
  text-align: left !important;
  height: 30px;
  text-transform: uppercase;
  font-size: 13px;
}

.ca-escrow-accounts-my-partners-escrow-accounts table .table-header-primary-accounts {
  background-color: #2c3c54;
  color: #fff;
  text-align: left !important;
  height: 30px;
  text-transform: uppercase;
  font-size: 13px;
}

.ca-escrow-accounts-payment-confirm .ca-form-row,
.ca-escrow-accounts-payment-done .ca-form-row {
  margin-bottom: 0;
}
.ca-escrow-accounts-payment-confirm .ca-form-row .title,
.ca-escrow-accounts-payment-done .ca-form-row .title {
  color: #4C7093;
  margin-bottom: 5px;
}
.ca-escrow-accounts-payment-confirm .ca-form-row .subtitle,
.ca-escrow-accounts-payment-done .ca-form-row .subtitle {
  display: none;
}

.ca-escrow-account-dropdown ul.dropdown-menu {
  left: 0px;
  z-index: 9999;
  padding: 0;
}
.ca-escrow-account-dropdown ul.dropdown-menu li {
  height: 100%;
}
.ca-escrow-account-dropdown ul.dropdown-menu li a {
  color: rgb(0, 51, 102);
  font-size: 13px;
  margin: 0;
  padding: 12px 20px;
  border-radius: 0;
}
.ca-escrow-account-dropdown ul.dropdown-menu li a:hover {
  background: #2c3c56;
  color: white;
  border-radius: 0;
}
.ca-escrow-account-dropdown ul.dropdown-menu li.divider {
  margin: 0;
  height: 1px;
}

.ca-integrations-hubspot .table-activity-rules .ca-select select {
  outline: none !important;
  box-shadow: none !important;
}

.authorized-users-export-csv-dropdown {
  margin-left: 15px;
}
.authorized-users-export-csv-dropdown ul.dropdown-menu {
  left: -177px;
  top: 35px;
  z-index: 9999;
  padding: 0;
  width: 184px;
}
.authorized-users-export-csv-dropdown ul.dropdown-menu li {
  height: 100%;
}
.authorized-users-export-csv-dropdown ul.dropdown-menu li a {
  color: rgb(0, 51, 102);
  font-size: 13px;
  margin: 0;
  padding: 12px 20px;
  border-radius: 0;
}
.authorized-users-export-csv-dropdown ul.dropdown-menu li a:hover {
  background: #2c3c56;
  color: white;
  border-radius: 0;
}
.authorized-users-export-csv-dropdown ul.dropdown-menu li.divider {
  margin: 0;
}

.ca-redemption-partnership-payment-term .special-feature-label {
  background-color: #2C3C54;
  color: #FFFFFF;
  font-size: 13px;
  font-style: italic;
  padding: 3px 13px;
  margin-left: 10px;
}
.ca-redemption-partnership-payment-term .recommended-label {
  background-color: #00B49C;
  color: #FFFFFF;
  font-size: 13px;
  font-style: italic;
  padding: 3px 13px;
  margin-left: 10px;
}
.ca-redemption-partnership-payment-term .label-small {
  font-size: 13px;
  color: #71787F;
  margin-left: 30px;
}

.ca-redemption-partnership-request-status .status-text {
  font-style: italic;
  padding: 0;
  text-transform: initial !important;
  color: gray;
}

.ca-send-redemption-partnership-request .wrapper-content {
  margin-top: 10px;
}

.ca-send-redemption-partnership-request-company-details .ca-form-row {
  margin-bottom: 10px;
}

.ca-redemption-partnership-request-partnership-request {
  margin-top: 15px;
}
.ca-redemption-partnership-request-partnership-request .ca-form-row {
  margin-bottom: 10px;
}

.ca-redemption-partnership-definition-partnership-definition {
  margin-top: 20px;
  padding-bottom: 50px;
}

.ca-redemption-partnership-details-partnership-information .ca-form-row {
  margin-bottom: 10px;
}

.ca-redemption-partnership-details-company-information .ca-form-row {
  margin-bottom: 10px;
}

.ca-invoices-record-payment-modal .modal-dialog {
  width: 600px !important;
}
.ca-invoices-record-payment-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px;
}
.ca-invoices-record-payment-modal .modal-dialog .modal-content .modal-body .upload-file {
  cursor: pointer;
  border: 1px dashed #00B49C;
  background: #FFF;
  display: block;
  padding: 10px;
  align-items: center;
  text-align: center;
  font-size: 13px;
  color: #00B49C;
}

.ca-invoices-update-status-modal .modal-dialog {
  width: 386px !important;
}
.ca-invoices-update-status-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px;
}

.invoices-main .ca-filter-row-2 .ca-simple-search-input input {
  width: 225px !important;
}
.invoices-main .ca-filter-row-2 .ca-multiselect {
  width: 150px;
  max-width: 150px;
}
.invoices-main .invoice-dropdown ul.dropdown-menu {
  left: -105px;
  z-index: 9999;
  padding: 0;
}
.invoices-main .invoice-dropdown ul.dropdown-menu li {
  height: 100%;
}
.invoices-main .invoice-dropdown ul.dropdown-menu li a {
  color: rgb(0, 51, 102);
  font-size: 13px;
  margin: 0;
  padding: 12px 20px;
  border-radius: 0;
}
.invoices-main .invoice-dropdown ul.dropdown-menu li a:hover {
  background: #2c3c56;
  color: white;
  border-radius: 0;
}
.invoices-main .invoice-dropdown ul.dropdown-menu li.divider {
  margin: 0;
}

@tailwind utilities;