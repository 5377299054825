#bank-form .form-container {
  width: 600px;
}
#bank-form .form-container h2 {
  color: #4C7093;
  font-size: 16px;
  font-weight: 600;
  margin-left: 13px;
}
#bank-form .form-container p.form-text {
  margin-left: 13px;
  margin-top: 20px;
  margin-bottom: 30px;
}
#bank-form .form-container .form-group {
  margin: 13px 0 !important;
}
#bank-form .form-container .form-group label {
  margin-top: 8px;
}