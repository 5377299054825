#partnership-form .modal-content {
  min-width: 1061px;
}
#partnership-form input {
  border: none;
}
#partnership-form .form-control[readonly] {
  cursor: auto;
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
}
#partnership-form .form-control[readonly]:focus {
  box-shadow: none;
}
#partnership-form .form-control:focus {
  border-color: rgba(0, 0, 0, 0) !important;
}
#partnership-form .input-list {
  margin-left: 22px;
  padding-top: 10px;
}