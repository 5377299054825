.tour-abstract #welcome {
  display: block !important;
  z-index: 2038 !important;
}
.tour-abstract .modal-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 2040;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.tour-abstract .modal-overlay-transparent {
  background-color: transparent;
  position: fixed;
  z-index: 2039;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}