.btn-lg-pad {
  padding-left: 100px;
  padding-right: 100px;
}

.full-width {
  width: 100%;
  display: inline-block;
}

.uppercase, .upp {
  text-transform: uppercase;
}

.bold {
  font-weight: bolder;
}

body {
  font-family: "Lato", sans-serif;
}

.well {
  border-radius: 0px;
  padding: 50px 100px;
}

.info-block {
  background-color: #f6fbff;
  padding-top: 70px;
  padding-bottom: 100px;
}

.block {
  padding-top: 70px;
  padding-bottom: 90px;
}

.white-text {
  color: white;
}

.lightt {
  font-weight: lighter;
}

.normalt {
  font-weight: normal !important;
}

.bg-darkprimary {
  background-color: #2C3C54;
}

.trans {
  background-color: rgba(255, 255, 255, 0);
}

.btn-trans {
  background-color: rgba(255, 255, 255, 0);
}

.pd10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pdt10 {
  padding-top: 10px;
}

.pdt80 {
  padding-top: 80px;
}

.pd60 {
  padding-bottom: 60px;
}

.sep {
  border-bottom: 1px solid #d2dde6;
  margin-top: 120px;
  margin-bottom: 70px;
}

.link-white {
  color: white;
  font-size: 16px;
  text-decoration: underline;
}
.link-white:hover {
  color: white;
  text-decoration: none;
}

.nph {
  padding-left: 0px;
  padding-right: 0px;
}

.npb {
  padding-bottom: 0px;
}

.nmb {
  margin-bottom: 0px;
}

.npl {
  padding-left: 0px;
}

.npr {
  padding-right: 0px;
}

.btn-primary {
  background-color: #357194;
  border-color: #357194;
}

.form input, .form textarea, .form select {
  padding-left: 20px;
  padding-right: 20px;
}
.form select, .form select option {
  border-radius: 0px !important;
}
.form .error {
  color: #d9534f;
}
.form .error input, .form .error textarea, .form .error select {
  border-color: #d9534f;
}
.form .control-group {
  margin-bottom: 20px;
}

.t-14 {
  font-size: 14px;
}

.form-control:focus, .single-line:focus {
  border-color: #357194 !important;
}

.borderless td, .borderless th {
  border: none;
}

.circle-number {
  display: inline-flex;
  width: 30px;
  min-width: 30px;
  height: 30px;
  background-color: #2C3C54;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-size: 14px;
  font-weight: lighter;
}

.ca-text-primary {
  color: #27547d !important;
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.ca-text-secondary {
  font-size: 13px !important;
  font-weight: lighter;
  color: #8da1a1 !important;
  line-height: 13px;
}

.ca-text-clear {
  color: #8EA9A9;
}

.ca-text-slg {
  font-size: 24px;
}

.ca-text-lg {
  font-size: 17px;
}

.ca-text-md {
  font-size: 16px;
}

.ca-text-sm {
  font-size: 10px;
}

.ca-text-xs {
  font-size: 13px;
}

.ca-more:after {
  content: " >>";
  letter-spacing: -3px;
}

.ca-link {
  color: #00B49C;
}
.ca-link:hover {
  text-decoration: none !important;
}

.ca-back-label-text {
  margin-top: 21px;
  margin-left: -26px;
}

.ca-font-normal {
  font-weight: normal;
}

.ca-bold {
  font-weight: 700;
}

.ca-thin {
  font-weight: 100;
}

.ca-disabled {
  color: #97ACBF;
}

.ca-italic {
  font-style: italic;
}

.ca-capitalize {
  text-transform: capitalize;
}

.ca-uppercase {
  text-transform: uppercase;
}

.ca-underline {
  text-decoration: underline;
}

.ca-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bolder;
  color: #4C7093;
  margin-bottom: 0;
}

.ca-subtitle {
  color: #003366;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
}

.ca-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.ca-required {
  display: inline-block;
  margin-top: 10px;
  color: #71787F;
  size: 13px;
}

.ca-panel {
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
}
.ca-panel .panel-heading {
  border-bottom: 1px solid #DDDDDD;
}
.ca-panel.ca-button-panel {
  box-shadow: none;
  background-color: #EEEEEE;
}

/*
<label class="control control-checkbox">
    {{ sector[1] }}
    <input type="checkbox"
        ng-model="vm.selectedSectors[sector[0]]"
        id="{{ sector[0] }}"
        value="{{ sector[0] }}">
    <div class="control_indicator"></div>
</label>
*/
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  border: 1px solid #c6d6df;
}

.control-radio .control_indicator {
  border-radius: 0;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #FFFFFF;
}

.control input:checked ~ .control_indicator {
  background: #FFFFFF;
}

.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #FFFFFF;
}

.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid #54B9AC;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #59BBAE;
}

.btn {
  font-weight: lighter !important;
  font-weight: lighter;
}
.btn[ng-disabled=true], .btn[disabled=true], .btn[disabled=disabled] {
  background-color: #DADADA;
  border-color: #DADADA;
}

.btn-narrow {
  width: 85%;
  padding: 10px;
}

.btn-no-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.btn-submit {
  background-color: #2D3C53;
  font-weight: 100;
  color: white;
}
.btn-submit:hover, .btn-submit:focus {
  color: white;
}
.btn-submit:active {
  background-color: #00B49C;
}

.btn-gray {
  background-color: #9B9B9B;
  color: #FFF;
}
.btn-gray:hover, .btn-gray:focus {
  color: #FFF;
}

.btn-green {
  background-color: #00B49C;
  color: #FFF;
  font-weight: lighter;
}
.btn-green:hover, .btn-green:focus {
  color: #FFF;
}

.btn-filter {
  padding: 7px 22px !important;
  font-weight: 100;
  font-size: 14px;
}

.btn-icon {
  padding-left: 10px;
  width: max-content;
}
.btn-icon .icon {
  margin-right: 20px;
  margin-top: -2px;
  border: 1px solid white;
  border-radius: 50%;
  padding: 4px;
  padding-left: 6px;
  font-size: 15px;
}

.ca-modal-form {
  font-weight: normal !important;
}
.ca-modal-form .circle {
  display: block;
  background-color: #003366;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50px;
  padding: 4px;
  color: #FFFFFF;
}
.ca-modal-form .ca-label {
  margin-top: 3px;
}
.ca-modal-form .control-radio {
  display: inline-block;
  width: 120px;
}

.modal-form .main-row {
  margin-top: 25px;
}
.modal-form .main-row:first-child {
  margin-top: 0;
}
.modal-form .main-row .ca-label-col {
  padding-top: 8px;
}
.modal-form .main-row .ca-label-col .ca-circle {
  display: block;
  background-color: #003366;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50px;
  padding: 4px;
  color: #FFFFFF;
}
.modal-form .main-row .ca-label-col label {
  margin-top: 4px;
  font-size: 14px;
}
.modal-form .main-row .back-label {
  margin-top: 10px;
  margin-left: -15px;
}

.ca-modal-body-form .row {
  margin-top: 20px;
  font-size: 14px;
}
.ca-modal-body-form .row:last-child {
  margin-top: 30px;
  text-align: center;
}
.ca-modal-body-form .row label {
  color: #003366;
}
.ca-modal-body-form .row textarea {
  font-size: 14px !important;
}

/*
<label class="control control-radio">
    <input type="radio">
    <div class="control_indicator"></div>
</label>
*/
.inline-radios {
  padding-top: 9px;
}
.inline-radios .control {
  display: inline !important;
  margin-left: 8px;
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 100 !important;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  border: 1px solid #c6d6df;
}

.control-radio .control_indicator {
  border-radius: 50%;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #FFFFFF;
}

.control input:checked ~ .control_indicator {
  background: #FFFFFF;
}

.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #FFFFFF;
}

.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-radio .control_indicator:after {
  left: 3px;
  top: 3px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #54BDAF;
}

.control-radio input:disabled ~ .control_indicator:after {
  background: #7b7b7b;
}

[id-prop] .dropdown-menu {
  height: 300px;
  width: max-content !important;
}
[id-prop] .btn-group .btn {
  text-align: left;
  width: 100%;
  height: 47px;
  padding: 10px 10px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c6d6df;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
[id-prop] .btn-group .btn .caret {
  display: none;
}

.ca-multiselect-initial .btn {
  height: 36px !important;
  padding-top: 6px !important;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: normal !important;
}

#toast-container > div {
  width: 100% !important;
}
#toast-container .toast {
  text-align: center;
}
#toast-container .toast-success {
  background-image: none !important;
}
#toast-container .toast-success:before {
  content: "";
}
#toast-container .toast-success .toast-message:before {
  font-family: FontAwesome;
  content: "\f00c";
  margin-right: 10px;
}
#toast-container .toast-error {
  background-image: none !important;
}
#toast-container .toast-error:before {
  content: "";
}
#toast-container .toast-error .toast-message:before {
  font-family: FontAwesome;
  content: "\f00d";
  margin-right: 10px;
}
#toast-container .toast-warning {
  background-image: none !important;
}
#toast-container .toast-warning:before {
  content: "";
}
#toast-container .toast-warning .toast-message:before {
  font-family: FontAwesome;
  content: "\f0e7";
  margin-right: 10px;
}

@media (min-width: 768px) {
  #page-wrapper {
    margin-left: 256px;
  }
  .navbar-static-side {
    z-index: 2001;
    position: absolute;
    width: 256px;
  }
}
@media (max-width: 768px) {
  .subitem-dot, .dot-line {
    display: none !important;
  }
  body.mini-navbar .navbar-default .nav li a span {
    display: inline;
  }
  .wrapper.wrapper-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: initial !important;
  }
}
.modal {
  /*
  <div class="modal fade" id="submit-request-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <div class="row">
                      <div class="col-xs-12">
                          <button type="button" class="close"
                              data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                          <h5 class="modal-title ca-text-primary ca-uppercase">YOUR PARTNERSHIP REQUEST TO ISSUE {{ vm.partner.loyalty_currency.long_name }}</h5>
                      </div>
                  </div>
              </div>
              <div class="modal-body">

              </div>
          </div>
      </div>
  </div>
  */
  padding-left: 240px !important;
  overflow-y: auto;
}
.modal .modal-dialog {
  width: 1004px !important;
  left: 0 !important;
}
.modal .modal-dialog .modal-content {
  border-radius: 0;
}
.modal .modal-dialog .modal-content .modal-header {
  padding: 30px 50px;
  background-color: #FFF;
}
.modal .modal-dialog .modal-content .modal-header.modal-header-blue {
  background-color: #4C7093;
  color: #FFF;
  padding-top: 30px;
  padding-bottom: 20px;
}
.modal .modal-dialog .modal-content .modal-header.modal-header-blue.with-check:before {
  content: url("/static/img/icons/check.svg");
  float: left;
  color: white;
  margin-right: 15px;
  fill: #fff;
}
.modal .modal-dialog .modal-content .modal-header.modal-header-blue.with-check:before svg {
  width: 50px;
  height: 50px;
}
.modal .modal-dialog .modal-content .modal-header.modal-header-blue p:first-child {
  font-size: 20px;
  font-weight: lighter;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: 2px;
}
.modal .modal-dialog .modal-content .modal-header.modal-header-blue p:last-child {
  font-size: 15px;
  font-weight: lighter;
}
.modal .modal-dialog .modal-content .modal-header .modal-title {
  text-transform: uppercase;
  color: #27547d !important;
  margin-bottom: 0;
  font-size: 16px;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 50px;
  padding-bottom: 30px;
  background-color: #F7F7F7;
  font-size: 14px !important;
}
.modal .modal-dialog .modal-content .modal-body .control {
  font-size: 14px;
}
.modal .modal-dialog .modal-content .modal-footer.modal-footer-gray {
  background-color: #EFEFF0;
  padding-top: 30px;
  padding-bottom: 30px;
}
.modal .modal-dialog .modal-content .modal-footer.ca-modal-footer {
  background-color: #F7F7F7;
  border-top: 0;
}
.modal .modal-dialog .modal-content .modal-footer.ca-modal-footer .row {
  margin-bottom: 15px;
}
.modal .modal-dialog .modal-content .modal-footer.ca-modal-footer .row a {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .modal {
    margin-left: 70px;
  }
  .modal .modal-dialog {
    width: 1004px !important;
    left: 0 !important;
  }
}
.ca-simple-modal ca-progress {
  margin-top: -19px;
  margin-right: 17px;
}
.ca-simple-modal .modal-title {
  font-size: 16px;
  color: #4C7093;
  text-transform: uppercase;
}

.ca-label {
  display: inline-block;
  padding: 5px 7px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  width: 87px;
  font-size: 14px;
}
.ca-label.Purchase {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Purchase:after {
  content: "Purchase";
}
.ca-label.Cancelation {
  border: 2px solid #D0021B;
  color: #D0021B;
}
.ca-label.Cancelation:after {
  content: "Cancelation";
}
.ca-label.Failure {
  border: 2px solid #D0021B;
  color: #D0021B;
}
.ca-label.Failure:after {
  content: "Failure";
}
.ca-label.Pending {
  border: 2px solid #F5A623;
  color: #F5A623;
}
.ca-label.Pending:after {
  content: "Pending";
}
.ca-label.Waiting_Acceptance {
  border: 2px solid #F5A623;
  color: #F5A623;
  white-space: pre;
  font-size: 13px;
}
.ca-label.Waiting_Acceptance:after {
  content: "Waiting \a Acceptance";
}
.ca-label.Pending_External_Approval {
  border: 2px solid #F5A623;
  color: #F5A623;
}
.ca-label.Pending_External_Approval:after {
  content: "Pending";
}
.ca-label.Pending_Approval {
  border: 2px solid #F5A623;
  color: #F5A623;
  white-space: pre;
  font-size: 13px;
}
.ca-label.Pending_Approval:after {
  content: "Pending \a Approval";
}
.ca-label.On_Hold {
  border: 2px solid #F5A623;
  color: #F5A623;
}
.ca-label.On_Hold:after {
  content: "On Hold";
}
.ca-label.Processing {
  border: 2px solid #F5A623;
  color: #F5A623;
}
.ca-label.Processing:after {
  content: "Processing";
}
.ca-label.Issued {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Issued:after {
  content: "Issued";
}
.ca-label.Active {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Active:after {
  content: "Active";
}
.ca-label.Enabled {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Enabled:after {
  content: "Enabled";
}
.ca-label.Finished {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Finished:after {
  content: "Finished";
}
.ca-label.Cancelled {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Cancelled:after {
  content: "Cancelled";
}
.ca-label.Declined {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Declined:after {
  content: "Declined";
}
.ca-label.Rejected {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Rejected:after {
  content: "Rejected";
}
.ca-label.Locked {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Locked:after {
  content: "Locked";
}
.ca-label.Blocked {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Blocked:after {
  content: "Blocked";
}
.ca-label.Disabled {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Disabled:after {
  content: "Disabled";
}
.ca-label.Accepted {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Accepted:after {
  content: "Accepted";
}
.ca-label.Terminated {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Terminated:after {
  content: "Terminated";
}
.ca-label.Confirmed {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Confirmed:after {
  content: "Confirmed";
}
.ca-label.Default {
  border: 2px solid #4C7093;
  color: #4C7093;
}
.ca-label.Default:after {
  content: "Default";
}
.ca-label.Success {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Success:after {
  content: "Success";
}
.ca-label.Failure {
  border: 2px solid #D0021B;
  color: #D0021B;
}
.ca-label.Failure:after {
  content: "Failure";
}
.ca-label.Completed {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Completed:after {
  content: "Completed";
}
.ca-label.Adjusted {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Adjusted:after {
  content: "Adjusted";
}
.ca-label.In_Progress {
  border: 2px solid #F5A623;
  color: #F5A623;
}
.ca-label.In_Progress:after {
  content: "In Progress";
}
.ca-label.Draft {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Draft:after {
  content: "Draft";
}
.ca-label.Conflict {
  border: 2px solid #D0021B;
  color: #D0021B;
}
.ca-label.Conflict:after {
  content: "Conflict";
}
.ca-label.Overdue {
  border: 2px solid #D0021B;
  color: #D0021B;
}
.ca-label.Overdue:after {
  content: "Overdue";
}
.ca-label.Paid {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Paid:after {
  content: "Paid";
}
.ca-label.Pro_Forma {
  border: 2px solid #4C7093;
  color: #4C7093;
}
.ca-label.Pro_Forma:after {
  content: "Pro Forma";
}
.ca-label.Written_Off {
  border: 2px solid #4C7093;
  color: #4C7093;
}
.ca-label.Written_Off:after {
  content: "Written Off";
}
.ca-label.Outstanding {
  border: 2px solid #F5A623;
  color: #F5A623;
  font-size: 13px;
}
.ca-label.Outstanding:after {
  content: "Outstanding";
}
.ca-label.Invoiced {
  border: 2px solid #7ED321;
  color: #7ED321;
}
.ca-label.Invoiced:after {
  content: "Invoiced";
}
.ca-label.Reversed {
  border: 2px solid #898989;
  color: #898989;
}
.ca-label.Reversed:after {
  content: "Reversed";
}

.ca-button-group {
  width: 100%;
}
.ca-button-group button {
  border: 1px solid #ddd;
  border-radius: 0 !important;
}
.ca-button-group button:first-child:nth-last-child(2), .ca-button-group button:first-child:nth-last-child(2) ~ button {
  width: 50%;
}
.ca-button-group button:first-child:nth-last-child(3), .ca-button-group button:first-child:nth-last-child(3) ~ button {
  width: 33.3333%;
}
.ca-button-group button:first-child:nth-last-child(4), .ca-button-group button:first-child:nth-last-child(4) ~ button {
  width: 25%;
}
.ca-button-group button.active {
  background-color: #2D3C53;
  color: #FFF;
}
.ca-button-group button.active:focus {
  background-color: #2D3C53;
  color: #FFF;
}

.ca-ribbon {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
  height: auto;
}
.ca-ribbon .owl-nav {
  height: 0;
}
.ca-ribbon .owl-nav .owl-prev, .ca-ribbon .owl-nav .owl-next {
  font-size: 30px;
  position: relative;
  z-index: 9999;
  display: inline-block;
  color: #4C7093;
}
.ca-ribbon .owl-nav .owl-prev {
  top: -73px;
  left: -16px;
}
.ca-ribbon .owl-nav .owl-next {
  display: inline;
  top: -73px;
  right: -1000px;
}
.ca-ribbon .ribbon-item {
  text-align: center;
  border: 1px solid #DADADA;
  border-radius: 0;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100px;
}
.ca-ribbon .ribbon-item.active {
  background-color: #2C3C54;
  color: #FFF;
}
.ca-ribbon .ribbon-item .data {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 0;
}
.ca-ribbon .ribbon-item .text {
  font-size: 14px;
  margin-bottom: 0;
}
.ca-ribbon .ribbon-item .subtext {
  margin-top: 10px;
  display: block;
  font-size: 12px;
  color: #71787F;
}

.ca-box-shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
}

.ca-box-bordered {
  border: 1px solid #ddd;
}

.ca-filter-row-2 ng-transclude {
  display: flex;
}
.ca-filter-row-2 ng-transclude > *:not(:last-child) {
  margin-right: 5px;
}
.ca-filter-row-2 ng-transclude .spacer {
  flex: 1;
}

.ca-filter-row-2 {
  display: flex;
}
.ca-filter-row-2 > *:not(:last-child) {
  margin-right: 5px;
}
.ca-filter-row-2 .spacer {
  flex: 1;
}
.ca-filter-row-2 select {
  width: 133px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.col-no-padded {
  padding-left: 2px;
  padding-right: 2px;
}
.col-no-padded:first-child {
  padding-left: 15px;
}
.col-no-padded:last-child {
  padding-right: 15px;
}

.ca-balance {
  display: flex;
  flex-direction: row;
}
.ca-balance .ca-title {
  width: 250px;
}
.ca-balance select {
  width: 205px !important;
  margin-left: 20px !important;
}
.ca-balance .balance-amount {
  margin-left: 30px;
  width: 200px;
}
.ca-balance .balance-money {
  width: 200px;
}
.ca-balance #manual-award-button {
  width: 280px;
  text-align: right;
}

.ca-row-sticky-footer {
  /*
  <span class="row ca-row-sticky-footer">
      <div class="col-xs-12">
          <button class="btn btn-submit">Save Changes</button>
      </div>
  </span>
  */
  position: fixed;
  bottom: 0;
  left: 255px;
  right: 0;
  background-color: white;
  height: 60px;
  text-align: center;
  z-index: 1001;
  -webkit-box-shadow: 0px -1px 13px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -1px 13px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -1px 13px -2px rgba(0, 0, 0, 0.75);
  display: block;
  padding-top: 8px;
}
.ca-row-sticky-footer .btn {
  margin-top: 8px;
}

.ca-alert-inline {
  border: 1px solid #71787F;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #71787F;
  font-weight: lighter;
  display: inline-block;
}

hr.tier-separator {
  border: 1px dashed #DADADA;
  margin-top: 20px;
}

.jconfirm .jc-bs3-container {
  left: 120px;
  position: relative;
}
.jconfirm .jconfirm-box {
  border-radius: 0 !important;
}
.jconfirm .jconfirm-box .jconfirm-buttons {
  width: 100%;
  text-align: center;
}
.jconfirm .jconfirm-box .jconfirm-buttons button {
  padding: 6px 25px;
  border-radius: 30px;
  text-transform: initial !important;
  font-weight: lighter !important;
}

input[cleave] {
  text-align: right;
}

.push-bottom {
  position: absolute;
  bottom: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.ca-message-body .row {
  margin-bottom: 15px;
  font-weight: 400;
}
.ca-message-body .row label {
  color: #003366;
  font-size: 14px;
}
.ca-message-body .row [class^=col-] > span {
  font-size: 14px;
  color: #003366;
}

.ca-dot-legend {
  display: flex;
  color: #71787F;
  font-size: 13px;
  font-weight: lighter;
}
.ca-dot-legend ca-dot {
  width: 110px;
}

.ca-simple-filter-row {
  height: auto;
}
.ca-simple-filter-row div[class^=col-] {
  padding-left: 6px;
  padding-right: 6px;
}
.ca-simple-filter-row div[class^=col-]:first-child {
  padding-left: 15px;
}
.ca-simple-filter-row div[class^=col-]:last-child {
  padding-right: 15px;
}
.ca-simple-filter-row div[class^=col-]:has(ca-tabs-filter) {
  background-color: red;
}

.ca-row {
  display: flex;
}
.ca-row > * {
  margin-right: 10px;
}
.ca-row.align-bottom {
  align-items: flex-end;
}
.ca-row.align-center {
  align-items: center;
}

[placeholder]::-webkit-input-placeholder {
  color: #9eb0af;
  font-weight: lighter;
  font-size: "13px";
}
[placeholder]:-moz-placeholder {
  color: #9eb0af;
  font-weight: lighter;
  font-size: "13px";
}
[placeholder]::-moz-placeholder {
  color: #9eb0af;
  font-weight: lighter;
  font-size: "13px";
}
[placeholder]:-ms-input-placeholder {
  color: #9eb0af;
  font-weight: lighter;
  font-size: "13px";
}

.form-control {
  padding: 0 7px !important;
}
.form-control:focus {
  box-shadow: none;
}

select {
  -webkit-border-radius: 0 !important;
  border: 0 !important;
  outline: 1px solid #C6D5DF !important;
  outline-offset: -1px !important;
  border-radius: 0 !important;
}
select.ng-empty {
  font-weight: 100 !important;
  color: #9eb0af !important;
}
select.init {
  font-weight: 100 !important;
  color: #9eb0af !important;
}
select.form-control {
  padding: 0 5px !important;
}

.input-group * {
  border-radius: 0 !important;
}

.superlabel {
  margin-bottom: 0;
  text-align: center;
}

textarea {
  padding: 10px 20px !important;
}

.ca-textarea {
  padding: 20px 20px !important;
  resize: none;
}

.has-error .multiselect button {
  border-color: #a94442 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.input-xs {
  height: 36px;
  font-size: 14px;
}

.ca-search-input {
  /*
  <div class="ca-search-input">
      <div class="row">
          <div class="col-md-3">
              <label for="search-input">Search</label>
          </div>
          <div class="col-md-9">
              <div class="input-group">
                  <input type="text" class="form-control input-sm" placeholder="Search">
                  <div class="input-group-addon">
                      <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
              </div>
          </div>
      </div>
  </div>
  */
}
.ca-search-input label {
  margin-top: 12px;
  font-size: 14px;
  margin-left: 15px;
}
.ca-search-input .input-group-addon {
  padding: 10px 16px;
  font-size: 19px;
  font-weight: 400;
  line-height: 1;
  color: #369;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: transparent;
}

.ca-input-up-label {
  /*
  <div class="ca-input-up-label">
      <label for="date_to">To</label>
      <select name="date_to" class="form-control input-sm">
          <option value="">April 2018</option>
      </select>
  </div>
  */
}
.ca-input-up-label label {
  color: #8EA9A9;
  font-size: 14px;
  font-weight: 100;
}

.ca-input-xs {
  height: 36px;
  font-size: 14px;
  line-height: 1.5;
}

.ca-form-label {
  font-size: 14px;
  color: #27547d !important;
  font-weight: normal;
}

.ca-select-error {
  border-radius: 0 !important;
  border: 1px solid #a94442 !important;
  outline: none !important;
}
.ca-select-error select {
  outline: none !important;
}

span.text-danger.ng-binding {
  font-size: 11px;
}

[cleave] {
  text-align: right !important;
}

.not-allowed {
  background-color: #fff !important;
  border: none !important;
  box-shadow: none;
  cursor: default !important;
}

.not-allowed-input-fake {
  font-size: 14px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 6px;
  color: #369;
}

.ca-form-horizontal .row {
  margin-top: 20px;
}
.ca-form-horizontal .row label {
  margin-top: 10px;
  font-size: 14px;
  font-weight: lighter !important;
}

.ca-numbered-input {
  /*
  <div class="row ca-numbered-input">
      <div class="col-xs-1 text-center">
          <span class="number">1</span>
      </div>
      <div class="col-xs-4">
          <span class="text">Choose the currency you want to buy</span>
      </div>
      <div class="col-xs-3">
          <select class="form-control input-xs">
              <option value="">Comcoins</option>
          </select>
      </div>
  </div>
  */
  margin-top: 25px;
}
.ca-numbered-input .number {
  display: inline-block;
  background-color: #003366;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50px;
  padding: 4px;
  color: #FFFFFF;
  font-weight: 100;
}
.ca-numbered-input .text {
  font-size: 14px;
  margin-top: 3px;
  display: inline-block;
  margin-left: -20px;
}
.ca-form-table {
  /*
  <table class="ca-form-table">
      <tr>
          <td>Name of Requestor</td>
          <td>Carla Max</td>
      </tr>
  </table>
  */
  border: none !important;
  font-size: 14px;
  font-weight: 100;
}
.ca-form-table tr {
  border: none !important;
}
.ca-form-table tr:first-child td {
  padding-top: 0;
}
.ca-form-table tr td {
  padding-top: 0 !important;
  height: auto !important;
  border: none !important;
}
.ca-form-table tr td:first-child {
  color: #71787f;
}
.ca-form-table tr td:last-child {
  color: #369;
}

.ca-horizontal-form {
  /*
  <div class="row ca-horizontal-form">
      <div class="col-xs-12">
          <div class="row" ng-class="{ 'has-error': vm.formErrors.long_name }">
              <label class="col-xs-3">
                  <p>Currency Long Name</p>
                  <span>Max 80 characters</span>
              </label>
              <div class="col-xs-4">
                  <input name="long_name"
                      type="text"
                      class="form-control input-xs"
                      ng-model="vm.currency.long_name"
                      size="80">
                  <span ng-if="vm.formErrors.long_name" class="text-danger">
                      {{ vm.formErrors.long_name[0] }}
                  </span>
              </div>
          </div>
      </div>
  </div>
  */
}
.ca-horizontal-form .row {
  margin-bottom: 20px;
}
.ca-horizontal-form .row label p {
  margin-top: -3px;
  margin-bottom: -5px;
  font-weight: normal;
  font-size: 14px;
}
.ca-horizontal-form .row label span {
  font-size: 13px;
  font-weight: lighter;
  color: #8DA1A1;
  line-height: 13px;
  display: inline-block;
}
.ca-horizontal-form .row .radio {
  margin-top: -7px;
}

.ca-simple-form .row {
  margin-top: 5px;
}
.ca-simple-form .row label {
  color: #AEAEAE;
  font-size: 13px;
  margin-top: 8px;
}

.ca-table th {
  color: #2C3C54;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  border-bottom-color: #4C7093 !important;
  vertical-align: middle !important;
}
.ca-table th.centered {
  text-align: center;
}
.ca-table td {
  color: #003366;
  font-size: 12px;
  vertical-align: middle !important;
  font-weight: normal;
  height: 40px;
}
.ca-table td.centered {
  text-align: center;
}
.ca-table td.number {
  text-align: right;
}
.ca-table td.number span {
  margin-right: 16px;
}
.ca-table .date {
  margin: -5px 0;
}
.ca-table .date .month {
  font-size: 11px;
  margin-bottom: -5px;
}
.ca-table .date .day {
  font-size: 17px;
  margin-bottom: -6px;
}
.ca-table .date .year {
  font-size: 8px;
  margin-bottom: 0;
  color: #AAAAAA;
}

tr.ca-tr-load-more {
  /*
  <tr class="ca-tr-load-more">
      <td colspan="4">
          <p>Showing 13 of 500</p>
          <button class="btn btn-load-more">
              Load more
          </button>
      </td>
  </tr>
  */
}
tr.ca-tr-load-more td {
  padding: 20px !important;
  text-align: center;
}
tr.ca-tr-load-more td p {
  text-align: left;
  color: #5E656C;
  font-size: 14px;
}

.ca-table-boxed {
  /*
  <div class="ca-table-boxed">
      <table>
          <thead>
              <tr>
                  <th>
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr>
              </tr>
          </tbody>
      </table>
  </div>
  */
  padding: 0 50px 0 50px;
  border: 1px solid #C6D5DF;
}
.ca-table-boxed table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  border-color: grey;
  margin-bottom: 0;
}
.ca-table-boxed thead tr {
  border-bottom: 1px solid #C6D5DF !important;
}
.ca-table-boxed tr {
  border-bottom: 1px solid #C6D5DF;
}
.ca-table-boxed tr:last-child {
  border-bottom: none;
}
.ca-table-boxed tr th, .ca-table-boxed tr td {
  padding: 13px;
}

.ca-table-detail {
  /*
  <table class="ca-table-detail">
      <tr>
          <td>Name</td>
          <td>Pedro Max <a href="" class="ca-link">(See the Transaction History with this member)</a></td>
      </tr>
  </table>
  */
  font-size: 14px;
  font-weight: normal !important;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
}
.ca-table-detail tr {
  height: 63px;
}
.ca-table-detail tr td {
  border: 1px solid #ddd;
  font-weight: normal;
  padding: 13px !important;
}
.ca-table-detail tr td:first-child {
  background-color: #4C7093;
  color: #FFF;
  width: 40%;
}

.ca-currency-order-modal .ca-table-detail tr,
.ca-purchase-order-modal .ca-table-detail tr {
  height: 53px;
}

.panel-body.has-table-chart {
  padding: 0;
}

.ca-table-chart {
  /*
  add .has-table-chart to .panel-body
  <table class="ca-table-chart">
          <colgroup>
              <col style="width: 3%;">
          </colgroup>
          <thead>
              <tr>
                  <th></th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td></td>
              </tr>
          </tbody>
      </table>
      */
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
}
.ca-table-chart thead tr {
  border-bottom: 2px solid #27547d;
}
.ca-table-chart thead tr th {
  color: #2C3C54;
  text-transform: uppercase;
  font-size: 12px;
  vertical-align: middle !important;
  font-weight: 400;
  height: 40px;
  border-bottom-color: #4C7093 !important;
  vertical-align: middle !important;
  padding: 0px 15px !important;
}
.ca-table-chart thead tr th.centered {
  text-align: center;
}
.ca-table-chart tbody tr {
  border-bottom: 1px solid #ddd;
}
.ca-table-chart tbody tr:last-child {
  border-bottom: none;
}
.ca-table-chart tbody tr td {
  max-width: 100px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #003366;
  font-size: 13px;
  vertical-align: middle !important;
  font-weight: 100;
  height: 40px;
  padding: 0px 15px !important;
}
.ca-table-chart tbody tr td.centered {
  text-align: center;
}
.ca-table-chart tbody tr td.number {
  text-align: right;
}
.ca-table-chart tbody tr td.number span {
  margin-right: 16px;
}

.ca-table-row {
  width: 100%;
  color: #003366;
  font-weight: normal;
}
.ca-table-row tr {
  border: 1px solid #C6D5DF;
  font-size: 14px;
}
.ca-table-row tr td {
  padding: 25px 18px;
}
.ca-table-row tr td.price-cell {
  text-align: right;
}
.ca-table-row tr td.price-cell span:first-child {
  display: inline-block;
  margin-right: 10px;
}
.ca-table-row tr td.price-cell span.price {
  display: inline-block;
  margin-right: 10px;
  font-size: 18px;
  top: 3px;
  position: relative;
}
.ca-table-row tr td.price-cell span.fiat-currency {
  font-size: 18px;
  top: 3px;
  position: relative;
}

.ca-first-rule-table {
  width: 100%;
  border: 1px solid #4c7093;
}
.ca-first-rule-table tr td {
  padding: 20px;
  vertical-align: middle;
}
.ca-first-rule-table tr td:first-child {
  padding-top: 26px;
}
.ca-first-rule-table tr td p {
  font-size: 18px;
  color: #036;
}

.ca-filter {
  width: 100%;
}
.ca-filter p {
  color: #8ea9a9;
  font-size: 14px;
  font-weight: 100;
}

.ca-table-new {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform-origin: 26% 71%;
  -moz-transform-origin: 26% 71%;
  transform-origin: 26% 71%;
  position: absolute;
  left: -9px;
  background-color: #f5a623;
  display: block;
  width: 56px;
  text-transform: uppercase;
  height: 32px;
  padding-top: 6px;
  -webkit-box-shadow: 0px -2px 15px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -2px 15px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -2px 15px -3px rgba(0, 0, 0, 0.75);
}

.ca-well {
  padding: 34px 48px !important;
}
.ca-well .well-header .ca-title {
  color: #4C7093;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 7px;
}
.ca-well .well-header .ca-subtitle {
  color: #003366;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
}
.ca-well .well-body {
  margin-top: 10px;
}
.ca-well .well-footer {
  margin-top: 10px;
}

.ca-well-2 {
  /*
  <div class="ca-well-2">
      <p class="title">company information</p>
      <form novalidate>
          <div class="row">
              <div class="col-xs-4">
                  <label>
                      <p>Company logo</p>
                      <small>Recommended size 50x50 px. The Logo is used only within the platform</small>
                  </label>
              </div>
              <div class="col-xs-9">
                  <input type="text" class="form-control input-xs">
              </div>
          </div>
      </form>
  </div>
  */
  padding: 34px 48px !important;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  background-color: #fff;
  font-size: 15px;
}
.ca-well-2 .title {
  color: #4C7093;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.ca-well-2 .subtitle {
  color: #036;
  font-size: 14px;
  font-weight: normal;
  margin-top: 10px;
}
.ca-well-2 form {
  margin-top: 30px;
}
.ca-well-2 form .row {
  margin-bottom: 20px;
}
.ca-well-2 form .row label p {
  color: #003366;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 7px;
}
.ca-well-2 form .row label small {
  color: #71787F;
  font-size: 13px;
  width: 200px;
  display: inline-block;
}
.ca-well-2 table {
  width: 100%;
  max-width: 100%;
}
.ca-well-2 table thead tr {
  border-bottom: 1px solid #4c7093;
}
.ca-well-2 table thead tr th {
  text-transform: uppercase;
  font-size: 12px;
  height: 50px;
  vertical-align: middle !important;
  border-bottom: none !important;
  padding: 8px;
  line-height: 1.428571429;
}
.ca-well-2 table tbody tr {
  border-top: 1px solid #ddd;
}
.ca-well-2 table tbody tr:first-child {
  border-top: transparent;
}
.ca-well-2 table tbody tr td {
  padding: 8px;
  line-height: 1.428571429;
  text-transform: capitalize;
  font-size: 14px;
  height: 50px;
  vertical-align: middle !important;
}
.ca-well-2 table tbody tr td:first-child {
  border-left: transparent;
}
.ca-well-2 table tbody tr td .control-radio {
  padding-top: 11px;
  display: inline-block;
}

highchart {
  display: block;
  width: 100%;
  max-width: 100%;
}

#change-password-modal label {
  font-size: 14px;
}

.ca-nav-menu {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 4;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
  margin-bottom: 5px;
  background-color: #FFF;
}
.ca-nav-menu .nav-wrapper {
  width: 1004px;
  margin-left: auto;
  margin-right: auto;
}
.ca-nav-menu.ca-nav-menu-modal {
  width: 1002px;
  position: relative;
  right: 35px;
}
.ca-nav-menu .nav-tabs {
  border-bottom-color: transparent;
}
.ca-nav-menu .nav-tabs li {
  float: none;
  display: inline-block;
  *display: inline; /* ie7 fix */
  zoom: 1; /* hasLayout ie7 trigger */
  vertical-align: middle;
  height: 50px;
}
.ca-nav-menu .nav-tabs li a {
  color: #517496;
  border-color: transparent;
  border-radius: 0;
  font-weight: normal;
  height: 50px;
  display: inline-block;
}
.ca-nav-menu .nav-tabs li a:hover {
  background-color: #FFF;
}
.ca-nav-menu .nav-tabs li a.disabled {
  color: #dbdbdb !important;
  font-weight: 100;
  cursor: not-allowed;
}
.ca-nav-menu .nav-tabs li a:focus {
  outline: none;
}
.ca-nav-menu .nav-tabs li.active a {
  font-weight: 700;
  border-bottom: 2px solid #00B49C;
  cursor: pointer;
}
.ca-nav-menu .nav-tabs li.active a:hover {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.ca-nav-menu .nav-tabs li.active a:focus {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.ca-nav-menu .nav-tabs li .ca-button {
  border: 1px solid #00B49C;
  color: #00B49C;
  outline: none;
  border-radius: 50px;
  padding: 7px 22px;
  font-weight: 400;
  font-size: 14px;
}

.ca-spinner {
  width: 70px;
  text-align: center;
  display: inline;
  justify-content: center;
}

.ca-spinner > div {
  width: 13px;
  height: 13px;
  background-color: #367294;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.ca-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.ca-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.ca-overlay {
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.ca-overlay.offset {
  top: 725px !important;
}
.ca-overlay .text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2D3C53;
  text-align: center;
  color: #FFF !important;
  padding: 5px;
  font-size: 14px !important;
  height: 30px;
  font-weight: 100 !important;
}
.ca-overlay .text a:hover {
  color: #00beaf;
}

.ca-breadcrumbs {
  color: #B4B4B4;
  font-size: 14px;
  margin-bottom: -20px;
  margin-left: 2px;
}
.ca-breadcrumbs li {
  margin-left: -7px;
}
.ca-breadcrumbs li a {
  color: #b4b4b4;
}

.ca-table-exchange .exchange-dot {
  display: inline-block;
  width: 40%;
  text-align: right;
}
.ca-table-exchange .exchange-dot .fa-circle {
  font-size: 10px;
  top: -5px;
  position: relative;
  color: #DADADA;
}
.ca-table-exchange .exchange-dot .fa-circle.on {
  color: #6BC03A;
}
.ca-table-exchange .exchange-sign {
  display: inline-block;
  width: 30px;
}
.ca-table-exchange .exchange-sign .fa.fa-times {
  font-size: 25px;
  color: #D20025;
}
.ca-table-exchange .exchange-sign .fa.fa-check {
  font-size: 25px;
  color: #00B69D;
}

.ca-list-to-number {
  display: inline-block;
}
.ca-list-to-number .count {
  font-size: 11px;
  color: #71787F;
  position: relative;
  top: -5px;
}
.ca-list-to-number.scope-list {
  cursor: context-menu !important;
}

.ca-scope {
  display: inline-block;
}
.ca-scope .popover {
  z-index: 10000000;
  width: fit-content;
  max-width: 1000px;
}
.ca-scope .popover .popover-content {
  -webkit-column-count: 10; /* Chrome, Safari, Opera */
  -moz-column-count: 10; /* Firefox */
  column-count: 10;
}
.ca-scope .popover .popover-content span {
  display: inline-block;
}

.ca-table-date {
  margin: -5px 0;
}
.ca-table-date .month {
  font-size: 11px;
  margin-bottom: -5px;
}
.ca-table-date .day {
  font-size: 17px;
  margin-bottom: -6px;
}
.ca-table-date .year {
  font-size: 8px;
  margin-bottom: 0;
  color: #AAAAAA;
}

.ca-tabs-filter {
  display: flex;
}
.ca-tabs-filter .tab {
  flex-grow: 1;
  flex-basis: 0;
  background-color: white;
  color: #003366;
  flex-grow: 1;
  text-align: center;
  font-weight: lighter;
  font-size: 14px;
  padding: 7px 1px;
  border: 1px solid #C6D5DF;
  margin-right: -1px;
  cursor: pointer;
}
.ca-tabs-filter .tab.active {
  background-color: #2C3C54;
  color: white;
}

.ca-progress-bar {
  display: inline-block;
  text-transform: initial;
}
.ca-progress-bar .point-list {
  list-style-type: none;
  margin: -5px 0 0 0;
  padding: 0;
  overflow: hidden;
}
.ca-progress-bar .point-list li {
  float: left;
  font-size: 11px;
}
.ca-progress-bar .point-list li.completed {
  font-weight: 700;
}
.ca-progress-bar .path, .ca-progress-bar .point {
  stroke: #DADADA;
  fill: #DADADA;
}
.ca-progress-bar .completed {
  stroke: #00B49C;
  fill: #00B49C;
}

.ca-pricing-tiers-edit {
  padding-bottom: 10px;
}
.ca-pricing-tiers-edit hr {
  margin-top: 10px;
}
.ca-pricing-tiers-edit .template-name {
  font-size: 17px;
}

.ca-paginator .btn-load-more {
  border-radius: 0;
  padding: 8px 30px 13px 30px;
  border: 1px solid #00B49C;
  background-color: transparent;
  font-size: 14px;
  color: #00B49C;
  text-transform: capitalize;
}
.ca-paginator .btn-load-more:hover, .ca-paginator .btn-load-more:focus {
  color: #00B49C;
}
.ca-paginator .btn-load-more:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 20px;
  margin-left: 9px;
  position: relative;
  top: 3px;
}

.ca-dot .fa-circle {
  font-size: 10px;
  color: #DADADA;
}
.ca-dot .fa-circle.green {
  color: #00B49C;
}

.ca-scope-selector {
  width: 100%;
}
.ca-scope-selector .row .col-xs-6:first-child {
  padding-right: 2px;
}
.ca-scope-selector .row .col-xs-6:last-child {
  padding-left: 2px;
}
.ca-scope-selector .selector {
  height: 36px;
  outline: none;
  padding: 7px;
  border: 1px solid #C6D5DF;
  color: #369;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
}
.ca-scope-selector .selector.ca-dirty {
  color: #369;
  font-weight: 400;
  font-size: 14px;
}
.ca-scope-selector .selector i {
  display: inline-block;
  position: relative;
  top: 3px;
  left: 0px;
}
.ca-scope-selector .selector.has-error {
  border-color: #a94442;
}
.ca-scope-selector .scopes-main-list {
  -webkit-padding-start: 0;
  padding-left: 0;
  list-style: none;
  max-height: 50vh;
  overflow: scroll;
  margin-bottom: 0;
  background-color: #FFF;
  border: 1px solid #c6d5df;
  font-size: 14px;
  position: absolute;
  width: 115%;
  z-index: 1000;
}
.ca-scope-selector .scopes-main-list li {
  padding: 6px;
}
.ca-scope-selector .scopes-main-list li .control {
  top: 6px;
}
.ca-scope-selector .scopes-main-list li span {
  display: inline-block;
  margin-left: 33px;
}
.ca-scope-selector .list {
  background-color: #fff;
  border-color: #fafafa;
  font-weight: normal;
  font-size: 14px;
  border: 1px solid #c6d5df;
  position: absolute;
  overflow: auto;
  width: 136%;
  z-index: 1000;
}
.ca-scope-selector .list input {
  margin-top: 2px;
  margin-left: 2px;
  width: 98%;
}
.ca-scope-selector .list .region-list {
  -webkit-padding-start: 0;
  list-style: none;
  overflow: scroll;
  margin-bottom: 0;
  max-height: 50vh;
  width: 100%;
}
.ca-scope-selector .list .region-list .region {
  padding-bottom: 0;
  margin-left: 7px;
  display: inline-block;
}
.ca-scope-selector .list .region-list .countries-list {
  -webkit-padding-start: 0;
  list-style: none;
  margin-bottom: 0;
}
.ca-scope-selector .list .region-list .countries-list span {
  margin-left: 30px;
}
.ca-scope-selector .list li {
  padding: 6px;
}
.ca-scope-selector .list li.search {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #FFF;
}
.ca-scope-selector .list li .control {
  top: 6px;
}
.ca-scope-selector .list li span {
  display: inline-block;
  margin-left: 33px;
}

.ca-loyalty-balance {
  display: inline-block;
  font-weight: normal;
}
.ca-loyalty-balance .units {
  text-transform: uppercase;
}
.ca-loyalty-balance.red {
  color: #D0021B !important;
}
.ca-loyalty-balance.green {
  color: #7ED321 !important;
}

.ca-picture-container {
  text-align: left;
}
.ca-picture-container .img {
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.ca-picture-container .img.round {
  border-radius: 200px;
}
.ca-picture-container .img.rectangle {
  width: 240px;
  height: 115px;
}
.ca-picture-container .img-placeholder {
  border: 1px dashed #C6D5DF;
  background-color: #FFF;
  width: 80px;
  height: 80px;
}
.ca-picture-container .img-placeholder.round {
  border-radius: 200px;
}
.ca-picture-container .img-placeholder.rectangle {
  width: 240px;
  height: 115px;
}

.ca-page-header {
  margin-bottom: 20px;
}
.ca-page-header .title {
  font-size: 16px;
  text-transform: uppercase;
  color: #4C7093;
  display: inline-block;
}
.ca-page-header .title a {
  text-transform: capitalize;
  font-size: 14px;
}
.ca-page-header .subtitle {
  font-size: 14px;
  color: #003366;
}

.ca-simple-filter {
  min-width: 133px;
  display: inline-block;
  width: 100%;
}
.ca-simple-filter label {
  font-size: 14px;
  color: #8EA9A9;
  font-weight: lighter !important;
  margin-bottom: 10.5px;
}

.ca-fixed-default-currency {
  display: inline;
}
.ca-fixed-default-currency .currency-code {
  display: inline-block;
  color: #369;
}

.ca-request-status-alert {
  border: 1px solid #71787f;
  padding: 15px 30px;
  text-align: center;
  font-size: 14px;
  color: #71787f;
  font-weight: lighter;
  display: inline-block;
}

.ca-link-well .title {
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
  color: #4c7093;
}
.ca-link-well .subtitle {
  display: block;
  color: #4c7093;
}
.ca-link-well .fa-angle-right {
  font-size: 52px;
}
.ca-link-well .ca-availabe-label {
  background-color: #B8E986;
  text-transform: uppercase;
  font-size: 10px;
  color: #00B49C;
  padding: 5px;
}
.ca-link-well.well-disabled .row div {
  opacity: 0.5;
  pointer-events: none;
}

.ca-back-button {
  color: #00beaf;
}
.ca-back-button i {
  font-size: 16px;
}
.ca-back-button span {
  display: inline-block;
  margin-left: 3px;
}
.ca-back-button:hover {
  text-decoration: none;
}

.ca-toggle-link .open-link {
  color: #00B49C;
  font-size: 14px;
  cursor: pointer;
}
.ca-toggle-link .open-link:after {
  font-family: FontAwesome;
  content: " \f107";
}
.ca-toggle-link .close-link {
  color: #00B49C;
  font-size: 14px;
  cursor: pointer;
}
.ca-toggle-link .close-link:after {
  font-family: FontAwesome;
  content: " \f106";
}

.ca-filter-date .ca-popover .ca-link {
  text-decoration: none;
}
.ca-filter-date .filter-range {
  min-width: 175px;
  height: 36px;
  outline: none;
  padding: 7px;
  border: 1px solid #c6d5df;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  color: #369;
  font-weight: 400;
  font-size: 14px;
}
.ca-filter-date .filter-range i {
  display: inline-block;
  position: relative;
  top: 3px;
  left: 0;
  margin-left: 15px;
}

.ca-white-modal-header {
  padding: 30px 50px;
  background-color: #FFF;
}
.ca-white-modal-header .close {
  vertical-align: middle;
  margin-top: 2px;
}
.ca-white-modal-header .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #4C7093;
  vertical-align: middle;
  @apply pd-font-bold;
}
.ca-white-modal-header .ca-progress {
  margin-top: 5px;
  margin-bottom: -20px;
}

.ca-panel-form-row {
  display: flex;
  margin-bottom: 30px;
}
.ca-panel-form-row .panel-form-label {
  display: flex;
  flex-direction: column;
  width: 25%;
}
.ca-panel-form-row .panel-form-label .panel-label {
  color: #036;
  font-size: 15px;
}
.ca-panel-form-row .panel-form-label .panel-sublabel {
  color: #71787f;
  font-size: 13px;
  width: 75%;
}
.ca-panel-form-row .panel-form-field {
  width: 75%;
  display: flex;
}
.ca-panel-form-row .panel-form-field > * {
  flex-grow: 1;
}

.ca-panel-2 {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
}
.ca-panel-2 .ca-panel-header {
  font-weight: bold;
  height: 60px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 0 40px;
  border-bottom: 2px solid #DADADA;
  color: #4C7093;
  justify-content: space-between;
}
.ca-panel-2 .ca-panel-body {
  height: auto;
  padding: 30px 40px;
}
.ca-panel-2 .ca-panel-body.fixed-height {
  height: 300px;
  padding: 0;
}
.ca-panel-2 .ca-panel-body.fixed-height.no-footer {
  height: 350px;
}
.ca-panel-2 .ca-panel-footer {
  border-top: 2px solid #DADADA;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  text-transform: capitalize;
}
.ca-panel-2 .ca-panel-footer a {
  font-size: 13px;
  color: #00B49C;
}

.ca-currency-name {
  display: inline-flex;
  word-break: break-word;
}
.ca-currency-name .name {
  display: inline-block;
  max-width: 150px;
  min-width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ca-currency-name .popover {
  color: #036 !important;
}

.ca-panel-form-row {
  display: flex;
  margin-bottom: 30px;
}
.ca-panel-form-row .panel-form-label {
  display: flex;
  flex-direction: column;
  width: 25%;
}
.ca-panel-form-row .panel-form-label .panel-label {
  color: #036;
  font-size: 15px;
}
.ca-panel-form-row .panel-form-label .panel-sublabel {
  color: #71787f;
  font-size: 13px;
  width: 75%;
}
.ca-panel-form-row .panel-form-field {
  width: 75%;
  display: flex;
}
.ca-panel-form-row .panel-form-field > * {
  flex-grow: 1;
}

.ca-panel-2 {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
}
.ca-panel-2 .ca-panel-header {
  font-weight: bold;
  height: 60px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 0 40px;
  border-bottom: 2px solid #DADADA;
  color: #4C7093;
  justify-content: space-between;
}
.ca-panel-2 .ca-panel-body {
  height: auto;
  padding: 30px 40px;
}
.ca-panel-2 .ca-panel-body.fixed-height {
  height: 300px;
  padding: 0;
}
.ca-panel-2 .ca-panel-body.fixed-height.no-footer {
  height: 350px;
}
.ca-panel-2 .ca-panel-footer {
  border-top: 2px solid #DADADA;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  text-transform: capitalize;
}
.ca-panel-2 .ca-panel-footer a {
  font-size: 13px;
  color: #00B49C;
}

.ca-well-form-row {
  margin-bottom: 20px;
}
.ca-well-form-row .field-col [class*=col-] {
  padding-right: 0;
}
.ca-well-form-row .title {
  display: block;
  text-transform: capitalize;
  font-size: 14px;
  color: #003366;
}
.ca-well-form-row .subtitle {
  display: inline-block;
  font-size: 13px;
  color: #71787F;
  font-weight: lighter;
  width: 165px;
}
.ca-well-form-row .input-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px;
  font-size: 14px;
}
.ca-well-form-row .input-row .control-radio {
  margin-bottom: 22px;
}
.ca-well-form-row .input-row input {
  width: 180px;
}

.ca-well.new {
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  background-color: #fff;
}
.ca-well.new .well-title {
  text-transform: uppercase;
  color: #4C7093;
  font-size: 16px;
  font-weight: bolder;
}
.ca-well.new .ca-well-footer {
  background-color: #f7f7f7;
  margin: 50px -48px 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 18px;
}
.ca-well.new#rule-settings-well {
  padding-bottom: 0 !important;
}

.ca-popover {
  display: inline-block;
}
.ca-popover .popover, .ca-popover + .popover {
  border-radius: 0;
}
.ca-popover .popover .popover-content, .ca-popover + .popover .popover-content {
  color: #4A4A4A;
  font-size: 13px;
  min-width: 150px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

#side-menu .popover {
  width: 177px;
}

.popover {
  border-radius: 0;
  font-size: 14px;
}

.ca-confirm-funds-notification {
  width: 100%;
  background-color: #4C7093;
  color: #fff;
  padding: 15px 30px;
  font-weight: lighter;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.ca-confirm-funds-notification .fa-times {
  margin-top: -11px;
  margin-right: -22px;
}

.ca-check-box-input {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #C6D5DF;
  color: #036;
  font-weight: normal;
  height: 100%;
}

.datepicker {
  border-radius: 0;
  z-index: 10000;
}

.ca-date-input-group {
  position: relative;
  width: 135px;
}
.ca-date-input-group .input {
  border-radius: 0 !important;
  padding: 0 15px;
  width: 133px;
  font-size: 14px;
}
.ca-date-input-group .input:focus {
  border: 1px solid #357194 !important;
}
.ca-date-input-group .input[disabled] {
  cursor: not-allowed !important;
}
.ca-date-input-group .input[disabled] + .ca-date-icon {
  cursor: not-allowed !important;
}
.ca-date-input-group .input[readonly] {
  cursor: pointer;
}
.ca-date-input-group .icon {
  position: absolute;
  top: 9px;
  left: 108px;
  cursor: pointer;
}
.ca-date-input-group .icon.disabled {
  cursor: not-allowed;
}

.ca-date-input {
  border-radius: 0 !important;
  padding: 0 15px;
  width: 133px;
}
.ca-date-input:focus {
  border: 1px solid #357194 !important;
}
.ca-date-input[disabled] {
  cursor: not-allowed !important;
}
.ca-date-input[disabled] + .icon {
  cursor: not-allowed !important;
}
.ca-date-input[readonly] {
  cursor: pointer;
}

.ca-date-icon {
  position: relative;
  top: -31px;
  left: 108px;
  cursor: pointer;
}

.ca-exchange-input {
  width: 439px;
  color: #369;
}
.ca-exchange-input.ca-input-error .inputs {
  border: 1px solid #D0021B;
}
.ca-exchange-input .labels {
  display: inline-flex;
  flex-direction: row;
}
.ca-exchange-input .labels:first-child {
  height: 22px;
  min-height: 22px;
}
.ca-exchange-input .labels:last-child span {
  font-size: 13px;
  font-weight: 400;
  color: #71787f;
}
.ca-exchange-input .labels span {
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
}
.ca-exchange-input .labels span:first-child {
  width: 286px;
}
.ca-exchange-input .labels span:last-child {
  width: 154px;
}
.ca-exchange-input .inputs {
  display: inline-flex;
  flex-direction: row;
}
.ca-exchange-input .inputs input {
  height: 51px;
  font-size: 20px;
  padding: 0 5px;
  border: 1px solid #c6d6df;
  outline: none;
}
.ca-exchange-input .inputs input:first-child {
  width: 237px;
}
.ca-exchange-input .inputs input:first-child:focus {
  border-color: #357194;
}
.ca-exchange-input .inputs input:first-child:disabled {
  background-color: #f7f7f7;
}
.ca-exchange-input .inputs input:last-child {
  width: 154px;
  background-color: #f7f7f7;
}
.ca-exchange-input .inputs button {
  border: 1px solid #c6d6df;
  outline: none;
  border-left: 0;
  border-right: 0;
}
.ca-exchange-input .inputs button:active {
  border-color: #BABABA;
}
.ca-exchange-input .inputs button > img {
  width: 34px;
}

.ca-number-input input {
  text-align: right;
}

.ca-number-formated-input .form-control {
  text-align: right;
}
.ca-number-formated-input .error {
  color: #a94442;
  font-size: 11px;
}

.ca-simple-search-input {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.ca-simple-search-input input {
  margin-right: 5px;
  width: 300px;
}
.ca-simple-search-input button {
  background-color: #2C3C54;
}
.ca-simple-search-input button:hover {
  background-color: #416c93;
}
.ca-simple-search-input button img {
  width: 20px;
  margin: 0 10px;
}

.ca-simple-search-input-outline {
  position: relative;
}
.ca-simple-search-input-outline input {
  padding-right: 45px !important;
}
.ca-simple-search-input-outline button {
  background-color: transparent;
  position: absolute;
  right: 0;
}
.ca-simple-search-input-outline button:hover {
  background-color: transparent;
}

.ca-tags-input tags-input {
  min-width: 100%;
  width: fit-content;
}
.ca-tags-input tags-input .host {
  margin-bottom: 0;
}
.ca-tags-input tags-input .remove-button {
  margin-right: 0;
  padding-right: 0;
  color: white !important;
}
.ca-tags-input tags-input .tag-list li a {
  display: inline;
}
.ca-tags-input tags-input .tags {
  border-radius: 0 !important;
  border-color: #c6d6df !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  min-height: 36px !important;
  padding-top: 2px;
}
.ca-tags-input tags-input .tags .input {
  height: 28px !important;
}
.ca-tags-input tags-input .tags .tag-item {
  background: #357194 !important;
  margin-top: 3px !important;
}
.ca-tags-input tags-input .tags .tag-item.selected {
  background-color: red !important;
}

.ca-template-name-input #template-name-input {
  width: 290px;
  border: none;
}
.ca-template-name-input #template-name-input:focus {
  outline: none;
}

.ca-unit-input {
  width: 20%;
}
.ca-unit-input .superlabel {
  text-align: center;
}
.ca-unit-input .input-number {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ca-unit-input .input-number img {
  width: 15px;
  height: 15px;
}

.ca-textarea-input {
  padding: 10px !important;
  font-size: 14px !important;
}
.ca-textarea-input textarea {
  resize: vertical;
}

.ca-timepicker-input {
  width: 140px;
  display: flex;
}
.ca-timepicker-input i {
  position: relative;
  top: 9px;
  left: -25px;
  font-size: 20px;
}

.bootstrap-timepicker-widget input {
  width: 35px !important;
}

.ca-select-2 {
  min-width: 150px;
}
.ca-select-2 .ca-select-error {
  border-radius: 0 !important;
  border: 1px solid #a94442 !important;
  outline: none !important;
}

.ca-pricing-tiers-template {
  padding: 30px 50px 5px;
  padding-top: 0;
  background-color: white;
  font-size: 14px;
}
.ca-pricing-tiers-template table {
  margin-bottom: 0;
}
.ca-pricing-tiers-template table tr:first-child td {
  border-top-color: rgba(0, 0, 0, 0);
}
.ca-pricing-tiers-template table tr td {
  height: 50px;
  vertical-align: middle;
  border-color: #C6D5DF;
}
.ca-pricing-tiers-template table tr td .title {
  font-size: 16px;
  font-weight: bolder;
}

.ca-table-row-spinner {
  text-align: center;
}

.ca-spinner {
  text-align: center;
  display: inline;
  justify-content: center;
}

.ca-spinner > div {
  width: 13px;
  height: 13px;
  background-color: #367294;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.ca-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.ca-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.redemption .sector-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  padding: 15px;
}
.redemption .sector-list label {
  font-size: 14px;
  font-weight: normal !important;
}

.ca-main-bank-panel .ca-main-bank-panel-row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  color: #4c7093;
}
.ca-main-bank-panel .ca-main-bank-panel-row:last-child {
  margin-bottom: 0;
}
.ca-main-bank-panel .ca-main-bank-panel-row .text {
  width: 90%;
}
.ca-main-bank-panel .ca-main-bank-panel-row .text .title {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.ca-main-bank-panel .ca-main-bank-panel-row .text .subtitle {
  font-size: 14px;
}
.ca-main-bank-panel .ca-main-bank-panel-row .switch {
  width: 10%;
  text-align: right;
}
.ca-main-bank-panel .panel-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.settings .input-slg {
  height: 100px;
  font-size: 2.3em;
  text-align: center;
  font-weight: 700;
}
.settings .btn-sticky {
  position: fixed;
  bottom: 7px;
  left: 50%;
  z-index: 1031;
}
.settings .form-scope-select-col .col-xs-6:first-child {
  padding-right: 15px;
}
.settings .form-scope-select-col .col-xs-6:last-child {
  padding-left: 15px;
}
.settings #currency-amount-account {
  box-shadow: none;
  border: 1px solid #c0d3df;
}
.settings #currency-amount-account .ca-amount {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 1.4em;
}
.settings .popover {
  left: 800px !important;
  margin-top: 65px;
  width: 170px;
  font-size: 0.75em;
  color: #777e85;
  z-index: 1000;
}
.settings .popover .popover-content {
  padding: 6px 10px;
}

.authorized-users-permissions .fa {
  color: #8da1a1;
}
.authorized-users-permissions table tr.header-row {
  background-color: #F7F7F7;
  border-right: 1px solid #F7F7F7;
  border-left: 1px solid #F7F7F7;
}
.authorized-users-permissions table tr.header-row:first-child {
  border-top: 1px solid #4C7093;
}
.authorized-users-permissions table tr.header-row:first-child td {
  border-top: none;
}
.authorized-users-permissions table tr.header-row td {
  text-transform: uppercase;
}
.authorized-users-permissions table tr.permissions-row td {
  border-left: 1px solid rgba(76, 112, 147, 0.3);
  border-right: 1px solid rgba(76, 112, 147, 0.3);
  text-align: center;
}
.authorized-users-permissions table tr.permissions-row td:last-child {
  border-right: none;
}
.authorized-users-permissions table tr th {
  text-transform: capitalize !important;
  text-align: center;
  font-size: 14px;
}

.ca-product-category .ca-tags-input tags-input .tags {
  min-height: 62px !important;
}

.ca-accounts-crud thead th {
  text-align: center;
}
.ca-accounts-crud .currency-row {
  background-color: #4C7093;
  color: #FFF;
}
.ca-accounts-crud .currency-row td {
  text-transform: uppercase !important;
}
.ca-accounts-crud .currency-row td:first-child {
  padding-left: 10px;
}
.ca-accounts-crud tr[class^=currency-account-row-] {
  background-color: #F7F7F7;
}

.ca-delete-tier-modal .modal-dialog {
  width: 700px !important;
}

.ca-tier-input {
  display: flex;
  align-items: center;
}
.ca-tier-input .input {
  display: flex;
  position: relative;
  align-items: center;
}
.ca-tier-input .input.tier-error {
  border: 1px solid #a94442;
}
.ca-tier-input .input.tier-error .error {
  font-size: 13px;
  color: #a94442;
}
.ca-tier-input .input div.level {
  width: 80px;
  background-color: #C6D5DF;
  text-align: center;
  font-size: 13px;
  padding-top: 9px;
  height: 36px;
}
.ca-tier-input .input input {
  padding-right: 25px !important;
  width: 185px;
}
.ca-tier-input .input .tier-input-move {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 8px;
  justify-content: center;
  cursor: ns-resize;
  top: 10px;
}
.ca-tier-input .input .tier-input-fiat-currency {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 28px;
  justify-content: center;
  cursor: ns-resize;
  top: 7.5px;
}
.ca-tier-input .trash {
  padding: 10px;
  width: 30px;
}
.ca-tier-input .trash .fa-trash {
  color: #D8D8D8;
}

.ca-tier-form {
  display: inline-block;
}
.ca-tier-form.is-subcription-based .dndPlaceholder {
  width: 635px;
}
.ca-tier-form .dndPlaceholder {
  width: 450px;
  height: 41px;
  background-color: #F3F3F4;
  margin-bottom: 10px;
}
.ca-tier-form li {
  list-style-type: none;
}
.ca-tier-form .input-space {
  display: flex;
}
.ca-tier-form .dndDraggingSource {
  display: none;
}

.delete-tier {
  width: 100%;
  height: 100px;
  background-color: #DADADA;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid #71787F;
}

.ca-rule-list {
  display: inline-block;
}
.ca-rule-list .dndPlaceholder {
  width: 462.48px;
  height: 41.42px;
  background-color: #F3F3F4;
  margin-bottom: 10px;
}
.ca-rule-list li {
  list-style-type: none;
}
.ca-rule-list .dndDraggingSource {
  display: none;
}
.ca-rule-list .items {
  display: flex;
  align-items: center;
  border: 1px solid #c6d6df;
  margin-bottom: 10px;
}
.ca-rule-list .items.not-enabled {
  opacity: 0.4;
}
.ca-rule-list .items .priority {
  padding: 10px;
  background-color: #C6D5DF;
}
.ca-rule-list .items .name {
  padding: 10px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ca-rule-list .items .actions {
  padding: 10px;
}
.ca-rule-list .items .actions .fa {
  padding-right: 5px;
}
.ca-rule-list .items .actions .fa-bars {
  cursor: ns-resize;
}
.ca-rule-list .items .actions .fa-pencil,
.ca-rule-list .items .actions .fa-trash {
  cursor: pointer;
  color: #D8D8D8;
}

.my-own-currency-rule-settings .currency-expiration .ca-well-form-row .input-row {
  justify-content: normal;
  min-height: 42px;
  flex-wrap: wrap;
}
.my-own-currency-rule-settings .currency-expiration .ca-well-form-row .input-row > div {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 5px 0;
}
.my-own-currency-rule-settings .currency-expiration .ca-well-form-row .input-row > div .months-label {
  padding-left: 65px;
}
.my-own-currency-rule-settings .currency-expiration .ca-well-form-row .input-row input {
  width: 60px;
  margin: 0 10px;
}

.ca-overview-bar {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.ca-overview-bar .title {
  width: 20%;
  text-transform: uppercase;
}
.ca-overview-bar .select {
  width: 20%;
}
.ca-overview-bar .balance {
  width: 22%;
  text-align: center;
  font-size: 17px;
}
.ca-overview-bar .fiat-balance {
  width: 14%;
  text-align: center;
  font-size: 13px;
  font-weight: lighter;
  color: #8da1a1;
}
.ca-overview-bar .button {
  width: 20%;
}

.ca-member-tiers-panel .arrow {
  display: flex;
  align-items: center;
  height: 79px;
  justify-content: flex-end;
}
.ca-member-tiers-panel .onoffswitch {
  position: absolute;
  top: 96px;
  left: 851px;
}

.my-own-currency__currency-orders .ca-filter-row-2 select {
  width: 100%;
}

.ca-advanced-options {
  font-size: 15px;
}
.ca-advanced-options .ca-value-range-input .input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ca-advanced-options .ca-value-range-input .input .labeled-field {
  display: flex;
  flex-direction: column;
  width: 150px;
}
.ca-advanced-options .ca-value-range-input .input .labeled-field span {
  display: inline-block;
  margin-bottom: 5px;
}
.ca-advanced-options .ca-value-range-input .input input.form-control {
  width: 130px;
}
.ca-advanced-options .ca-value-range-input .input ::-webkit-input-placeholder {
  text-align: right;
}
.ca-advanced-options .ca-value-range-input .input input:-moz-placeholder {
  text-align: right;
}
.ca-advanced-options .ca-value-range-input .errors {
  color: #a94442;
  font-size: 11px;
}
.ca-advanced-options .ca-value-range-input .errors > span:first-child {
  margin-left: 138px;
}
.ca-advanced-options .ca-value-range-input .errors > span:last-child {
  margin-left: 159px;
}
.ca-advanced-options .calendar-input-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.ca-currency-given-field {
  font-size: 14px;
}

.ca-simulation {
  background-color: #f7f7f7;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: -30px;
  padding: 30px 40px;
}

.ca-simulation-modal .sublabel {
  color: #71787f;
  font-size: 13px;
  width: 60%;
  display: block;
}
.ca-simulation-modal .input-currency-amount {
  width: 200px;
  margin-right: 0;
}
.ca-simulation-modal .input-currency-amount input {
  font-weight: bold;
}
.ca-simulation-modal .simulation-no-result {
  margin-top: 40px;
  border: 1px dashed #d9d9d9;
  padding: 50px 0;
}
.ca-simulation-modal .simulation-no-result p {
  color: #71787f;
  text-align: center;
}
.ca-simulation-modal .simulation-result {
  margin-top: 40px;
  border: 1px dashed #d9d9d9;
  padding: 20px;
}
.ca-simulation-modal .simulation-result .resulting-points {
  color: white;
  background-color: #2c3c56;
  padding: 10px 0;
  text-align: center;
  font-size: 15px;
}
.ca-simulation-modal .simulation-result .resulting-points b {
  font-size: 29px;
}
.ca-simulation-modal .simulation-result .simulation-details {
  margin-left: 20px;
  margin-right: 10px;
  color: #30405a;
  font-size: 13px;
}
.ca-simulation-modal .simulation-result .simulation-message {
  font-size: 13px;
}

.rule-dropdown ul.dropdown-menu {
  left: -10px;
  z-index: 9999;
  padding: 0;
}
.rule-dropdown ul.dropdown-menu li {
  height: 100%;
}
.rule-dropdown ul.dropdown-menu li a {
  color: rgb(0, 51, 102);
  font-size: 13px;
  margin: 0;
  padding: 12px 20px;
  border-radius: 0;
}
.rule-dropdown ul.dropdown-menu li a:hover {
  background: #2c3c56;
  color: white;
  border-radius: 0;
}
.rule-dropdown ul.dropdown-menu li.divider {
  margin: 0;
}

.archived-rules .ca-back-header {
  margin-left: 0;
  margin-right: 0;
}

.ca-rule-currency-info {
  display: flex;
  justify-content: space-between;
}
.ca-rule-currency-info i {
  width: 45px;
  color: #00beaf;
}
.ca-rule-currency-info span {
  font-size: 13px;
  color: #71787f;
}

.user-welcome {
  text-align: center;
  background-color: #F7F7F7;
  font-weight: lighter !important;
}
.user-welcome .header {
  background-color: #003366;
  padding: 25px;
}
.user-welcome .container {
  min-width: 400px;
}
.user-welcome .container h2 {
  font-size: 50px;
  font-weight: lighter !important;
}
.user-welcome .container .well {
  background-color: #FFF;
}
.user-welcome .container .well label {
  font-size: 18px;
  color: #9B9B9B;
  font-weight: lighter !important;
}
.user-welcome .container p {
  font-size: 18px;
  color: #4A4A4A;
}

.ca-message-input textarea {
  border-bottom: none;
}
.ca-message-input .textarea-footer {
  background-color: #fff;
  padding: 30px 0;
  border-right: 1px solid #c6d6df;
  border-left: 1px solid #c6d6df;
  border-bottom: 1px solid #c6d6df;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.ca-message-input .textarea-footer.disabled {
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.ca-message-input .textarea-footer.focused {
  border-color: #357194 !important;
  outline: 0;
}
.ca-message-input .textarea-footer .btn-close {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  font-size: 14px;
}
.ca-message-input ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 13px;
  color: #71787F;
  font-weight: lighter;
}
.ca-message-input :-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-size: 13px;
  color: #71787F;
  font-weight: lighter;
}
.ca-message-input ::-ms-input-placeholder { /* Microsoft Edge */
  font-size: 13px;
  color: #71787F;
  font-weight: lighter;
}

.ca-modal-header {
  padding: 30px 50px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ca-modal-header .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #4C7093;
  flex: 1;
  @apply pd-font-bold;
}
.ca-modal-header .back-link {
  color: #00beaf;
  font-weight: lighter;
  font-size: 13px;
  border-right: 1px solid #DADADA;
  padding-right: 10px;
  margin-right: 10px;
}

.ca-modal-form-row {
  display: flex;
  align-items: center;
  height: auto;
  font-size: 14px;
  font-weight: normal !important;
  width: 100%;
  margin-top: 30px;
}
.ca-modal-form-row#modal-form-row-1 {
  margin-top: 0;
}
.ca-modal-form-row .step-col {
  width: 5%;
  text-align: center;
  display: inline-block;
  align-self: flex-start;
}
.ca-modal-form-row .step-col .circle {
  display: inline-block;
  background-color: #003366;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50px;
  padding: 4px;
  color: #FFFFFF;
}
.ca-modal-form-row .question-col {
  display: inline-block;
  width: 30%;
  overflow: hidden;
  text-overflow: clip;
  align-self: flex-start;
  margin-left: 25px;
  padding-top: 5px;
}
.ca-modal-form-row .field-col {
  display: inline-block;
  width: 50%;
  padding: 5px 10px;
}

/*
<table class="table table-bordered modal-table">
    <tr>
        <th>Date</th>
        <td>{{ $ctrl.transaction.created_at | date }}</td>
    </tr>
</table>
*/
.modal-table tr {
  height: 50px;
}
.modal-table tr th, .modal-table tr td:first-child {
  background-color: #4C7093;
  color: #FFF;
  font-size: 14px;
  font-weight: 100;
  vertical-align: middle !important;
  text-transform: capitalize;
  width: 50%;
}
.modal-table tr td {
  font-size: 14px;
  font-weight: 500;
  color: #4C7093;
  vertical-align: middle !important;
}

.small {
  width: 50%;
  margin: 0 auto;
}

.medium {
  width: 70%;
  margin: 0 auto;
}

.modal {
  overflow: auto !important;
}

.onoffswitch {
  text-align: left;
  display: inline-block;
}
.onoffswitch:focus {
  outline: none;
}

.onoffswitch-label {
  height: 24px !important;
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: none !important;
  border-radius: 12px !important;
  margin-bottom: 0 !important;
}
.onoffswitch-label:focus {
  outline: none;
}

.onoffswitch-switch {
  border: none !important;
  right: 30px;
  margin-right: 3px;
}

.onoffswitch-inner::after, .onoffswitch-inner::before {
  height: 24px !important;
  line-height: 24px !important;
}

.onoffswitch-inner::after {
  background-color: #eeeff1;
  color: #aeb9c3;
}

.onoffswitch-switch {
  top: 3px;
  height: 18px;
  bottom: auto;
  border-radius: 10px;
}

.onoffswitch [disabled] + .onoffswitch-label .onoffswitch-inner::before {
  background-color: #79e1cc;
  color: white;
}

.topnavbar .navbar-top-links {
  margin-top: -10px;
}
.topnavbar .navbar-top-links > li {
  float: left;
  margin-left: 35px;
}
.topnavbar .navbar-top-links > li:last-child {
  margin-right: 0;
}
@media (min-width: 1600px) {
  .topnavbar .navbar-top-links > li:last-child {
    margin-left: 110px;
  }
}
@media (min-width: 1800px) {
  .topnavbar .navbar-top-links > li:last-child {
    margin-left: 200px;
  }
}
.topnavbar .navbar-top-links > li.first-steps-tasks a {
  margin-top: -10px;
}
.topnavbar .navbar-top-links > li.first-steps-tasks a p {
  color: #ea0000;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
}
.topnavbar .navbar-top-links > li.first-steps-tasks a p.completed {
  color: #00b49c;
}
.topnavbar .navbar-top-links > li .user-data {
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.topnavbar .navbar-top-links > li .user-data i {
  padding-left: 20px;
}
.topnavbar .navbar-top-links > li .user-data .data-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 20px;
  width: auto;
}
.topnavbar .navbar-top-links > li .user-data .data-container .user-name {
  font-size: 16px;
  color: #4C7093;
  font-weight: lighter;
}
.topnavbar .navbar-top-links > li .user-data .data-container .company-name {
  font-size: 13px;
  color: #71787F;
  font-style: italic;
  font-weight: lighter;
  padding-right: 1px;
}

[state="dashboard.home"] .topnavbar .navbar-top-links > li.first-steps-tasks:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 28px 40px;
  border-color: transparent transparent #00b49c;
  position: absolute;
  bottom: -50px;
  z-index: 99;
}
@media (min-width: 2200px) {
  [state="dashboard.home"] .topnavbar .navbar-top-links > li.first-steps-tasks:before {
    display: none;
  }
}

.ca-form-error {
  margin-top: 5px;
  color: #a94442;
  font-size: 11px;
}

.ca-top-row {
  display: flex;
}
.ca-top-row .clearfix {
  background-color: darkgreen;
  flex-grow: 1;
}
.ca-top-row .ca-filter-col {
  padding: 0 2px;
  display: flex;
  align-items: flex-end;
}
.ca-top-row .ca-filter-col:first-child {
  padding-left: 0;
}
.ca-top-row .ca-filter-col:first-child > * {
  width: 133px;
}
.ca-top-row .ca-filter-col:last-child {
  padding-right: 0;
}

.metismenu .forbidden {
  border-color: #71787F !important;
}
.metismenu .forbidden a {
  color: #71787F !important;
  cursor: default !important;
}
.metismenu .forbidden a:hover {
  background: #2C3C54 !important;
}

#switch-sidebar {
  position: fixed;
  z-index: 10000;
}
#switch-sidebar.switch-sidebar-opened .switch-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: default;
}
#switch-sidebar.switch-sidebar-opened .switch-sidebar-modal {
  opacity: 1;
  transform: translateX(0);
}
#switch-sidebar.switch-sidebar-closed {
  pointer-events: none;
}
#switch-sidebar.switch-sidebar-closed .switch-sidebar-overlay {
  pointer-events: none;
}
#switch-sidebar.switch-sidebar-closed .switch-sidebar-modal {
  opacity: 0;
  transform: translateX(-100%);
}
#switch-sidebar .switch-sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
#switch-sidebar .switch-sidebar-modal {
  position: fixed;
  width: 256px;
  background-color: #2c3c54 !important;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
#switch-sidebar .switch-sidebar-modal .switch-sidebar-btn {
  display: flex;
  padding: 1.6rem;
  margin-bottom: 0.2rem;
  background-color: #00B49C;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#switch-sidebar .switch-sidebar-modal .switch-sidebar-btn:focus {
  outline: none;
}

span[class*=mt] {
  display: inline-block;
}

.row.center-vertical {
  display: flex;
  align-items: center;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

tr.is-new:before {
  content: "NEW";
  position: absolute;
  left: -33px;
  background-color: #F5A623;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  padding: 5px 16px;
  top: 69px;
  font-size: 14px;
}

.np {
  padding: 0px;
}

body {
  background: #2C3C54;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  background-color: #fff;
}

.ca-anchor-disable {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: #ccc !important;
}

.wrapper.wrapper-content {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
.wrapper.wrapper-content.main {
  padding-bottom: 70px !important;
}

.bg-white {
  background-color: white;
}

.bg-greydark {
  background-color: #f1eff0;
}

.text-green {
  color: #00B49C;
}

.btn-green {
  background-color: #00B49C;
  color: white;
}

.btn-grey {
  background-color: #f1eff0;
  color: white;
}

.btn-darkergrey {
  background-color: #b5bbc1;
  color: white;
}
.btn-darkergrey:hover {
  color: white;
  background-color: #8b8f94;
}

.text-bold {
  font-weight: bolder;
}

.link-green {
  color: #00B49C;
}
.link-green:hover {
  color: #2C3C54;
}

.text-mid {
  vertical-align: middle !important;
}

.input-sm {
  border-radius: 0 !important;
}

.navbar-static-side {
  background-color: #2C3C54 !important;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.navbar-static-side ul:first-child .nav-header {
  padding: 28px 25px 28px 25px;
}
.navbar-static-side ul li {
  border-bottom: 1px solid rgb(76, 112, 147);
}
.navbar-static-side ul .elem a {
  padding: 13px 10px 13px 21px;
}
.navbar-static-side ul .elem a img {
  margin-right: 20px;
}

.nav-space {
  height: 100px;
}

.navbar-static-side {
  position: fixed !important;
}

.navbar-default {
  text-transform: capitalize;
}

.nav-dash .section-title {
  margin-top: -11px;
}
.nav-dash .section-title .tit {
  font-size: 25px;
  font-weight: normal;
  color: #357395;
}
.nav-dash .section-title img {
  margin-right: 18px;
  margin-top: -10px;
  max-width: 40px;
}
.nav-dash .navbar {
  padding: 36px 40px 0 40px;
  background-color: #FFF !important;
  margin-left: 220px;
}
.nav-dash .navbar-bb {
  border-bottom: 1px solid #bfd0da;
}
.nav-dash .count-info {
  color: #357395;
}
.nav-dash .count-info img {
  margin-left: 10px;
}
.nav-dash .dropdown-toggle {
  cursor: pointer;
}
.nav-dash .dropdown-menu {
  z-index: 1000;
  margin-top: 12px;
  border-radius: 0px;
  background-color: #2C3C54;
  width: 300px;
  padding: 0px;
}
.nav-dash .dropdown-menu li a {
  color: white;
  font-size: 18px;
  padding: 20px 25px;
  margin: 0px;
  border-radius: 0px;
}
.nav-dash .dropdown-menu li a img {
  margin-right: 15px;
}
.nav-dash .dropdown-menu li a:hover, .nav-dash .dropdown-menu li a:active, .nav-dash .dropdown-menu li a:focus {
  background: #357194;
}
.nav-dash .dropdown-menu .divider {
  color: #bfd0da;
  margin: 0px;
}
.nav-dash .bread {
  z-index: 10;
  min-height: inherit;
  padding: 25px;
}
.nav-dash .bread .breadcrumb {
  margin: 0px;
  padding-left: 44px;
  background-color: inherit;
}

.sidebar-collapse .nav-header {
  background: #2C3C54;
}
.sidebar-collapse .nav a {
  color: white;
}
.sidebar-collapse .nav > li > a {
  background: #2C3C54;
}
.sidebar-collapse .nav > li > a:hover, .sidebar-collapse .nav > li > a:active, .sidebar-collapse .nav > li > a:focus {
  background: #357194;
}

.auth .header {
  height: 100px;
  padding: 35px 0;
  margin: 0px;
}
.auth .bg-auth {
  background: url("/static/img/bg-auth.jpg");
  min-height: 848px;
  background-position: top center;
  background-size: cover;
}
.auth .footer-auth {
  background-color: #072238;
  height: 63px;
}
.auth .footer-auth span, .auth .footer-auth ul > li > a {
  color: white;
  line-height: 63px;
  color: #9999cc;
}
.auth .tit {
  padding: 60px 0;
  color: white;
  font-size: 35px;
}
.auth .middle-box {
  max-width: 470px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
}
.auth form {
  padding: 0px 55px;
}
.auth .form-group input {
  font-weight: normal;
  color: white;
  padding: 10px 22px;
  background-color: rgba(255, 255, 255, 0);
}
.auth .form-group input::-webkit-input-placeholder, .auth .form-group textarea::-webkit-input-placeholder {
  color: white;
}
.auth .form-group input:-moz-placeholder, .auth .form-group textarea:-moz-placeholder {
  color: white;
}
.auth .links {
  padding: 20px 0;
}
.auth .btn-trans {
  border-color: white;
  color: white;
}
.auth .btn-trans:hover, .auth .btn-trans:active {
  background-color: white;
  color: #357194;
}

.quick-links .ibox-content {
  padding: 0px;
}
.quick-links ul li {
  border-bottom: 1px solid #e2eaed;
}
.quick-links ul li a {
  line-height: 64px;
  padding-left: 30px;
}
.quick-links ul li:last-child {
  border-bottom: none;
}
.quick-links ul:last-child {
  border-bottom: none;
}

.ibox-title {
  border-width: 0;
  padding-left: 30px;
  font-weight: normal;
}

.ibox-content, .ibox-title {
  border-color: #e2eaed;
}

.trans-snippet .ibox-tools {
  margin-top: -20px;
}

.widget-data {
  margin: 30px 0px;
  border: 1px solid #e7eaec;
}
.widget-data.text-danger .data {
  color: red;
}
.widget-data .widget-head-color-box {
  border-radius: 0px;
  margin-top: 0px;
  position: relative;
  padding: 0px;
}
.widget-data .widget-text-box {
  padding: 0px 5px;
}
.widget-data .text {
  color: #fff;
  font-size: 14px;
  line-height: 60px;
  display: inline-block;
  margin-left: 17px;
}
.widget-data .data {
  font-size: 22px;
  line-height: 60px;
  color: #4C7093;
}
.widget-data .data-vert {
  border: 1px solid #bfd0da;
  border-left: 0px;
  height: 60px;
}
.widget-data .ca-icon {
  width: 38px;
  height: 38px;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 10px;
  background-repeat: no-repeat;
}

.widget-data.issued-cc .widget-head-color-box, .widget-data.issued-cc .bg {
  background-color: #1f3a53;
}
.widget-data.issued-cc .ca-icon {
  background: url("/static/img/logo-issiued.png");
  background-repeat: no-repeat;
}

.widget-data.pending-cc .widget-head-color-box, .widget-data.pending-cc .bg {
  background-color: #fba152;
}
.widget-data.pending-cc .ca-icon {
  background: url("/static/img/logo-pending.png");
  background-repeat: no-repeat;
  top: 15px;
}

.widget-data.balance .widget-head-color-box, .widget-data.balance .bg {
  background-color: #00beaf;
}
.widget-data.balance .ca-icon {
  background: url("/static/img/logo-balance.png");
  background-repeat: no-repeat;
  top: 15px;
}

.widget-data.members .widget-head-color-box, .widget-data.members .bg {
  background-color: #5798d2;
}
.widget-data.members .ca-icon {
  background: url("/static/img/logo-member.png");
  background-repeat: no-repeat;
  top: 15px;
}

.alert {
  margin-bottom: 0 !important;
}

.alert.alert-ca {
  background-color: #4C7093;
  padding: 25px 36px;
  color: white;
  position: relative;
}
.alert.alert-ca .close {
  background: url("/static/img/cross-white.png");
  background-repeat: no-repeat;
  top: 19px;
  right: 20px;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  margin-top: 12px;
}
.alert.alert-ca .tit {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
}

.ibox-content .btn-row {
  margin-top: 15px;
}

.acc-snippet .acc-info {
  padding: 10px 0;
  font-size: 20px;
}
.acc-snippet .acc-info .identifier {
  width: 110px;
  display: inline-block;
}
.acc-snippet .acc-info .sep {
  margin: 0px 10px;
}
.acc-snippet .link-green {
  line-height: 48px;
}

.cc-vert .widget-data {
  margin: 15px 0;
}

.login-info .row-data {
  margin-bottom: 10px;
}

input.form-control, select.form-control {
  padding: 10px 20px;
}

select.form-control {
  border-radius: 0px;
}

.borderless .table > tbody > tr > td, .borderless .table > tbody > tr > th, .borderless .table > tfoot > tr > td, .borderless .table > tfoot > tr > th, .borderless .table > thead > tr > td {
  border-top: none;
}

.borderless td, .borderless th, .borderless tr, .borderless, .borderless tbody, .borderless thead {
  border: none;
}

.metismenu.nav > li.active {
  border-left: none;
}
.metismenu .active > a {
  background-color: #4C7093 !important;
}
.metismenu .active.parent > a {
  background-color: #4C7093 !important;
}
.metismenu.nav > li > a:hover, .metismenu.nav > li > a:active, .metismenu.nav > li > a:focus {
  background: #4C7093;
}
.metismenu .parent .nav-second-level a {
  padding-left: 30px;
  font-size: 16px;
}
.metismenu .parent .nav-second-level a .subitem-dot {
  background: #4C7093;
  border-radius: 40px;
  height: 10px;
  width: 10px;
  max-width: none;
  margin-right: 10px;
  display: inline-flex;
  z-index: 20;
  position: relative;
}
.metismenu .parent .nav-second-level a:hover .subitem-dot {
  background: white none repeat scroll 0 0;
}
.metismenu .parent .nav-second-level li.active {
  color: white;
}
.metismenu .parent .nav-second-level li.active > a {
  background: #425065 !important;
}
.metismenu .parent .nav-second-level li.active > a:hover {
  background: #425065 !important;
}
.metismenu .parent .nav-second-level li.active .subitem-dot {
  background: white none repeat scroll 0 0;
  position: relative;
}
.metismenu .parent .nav-second-level li {
  position: relative;
  margin-bottom: 0;
  background: none;
}
.metismenu .parent .nav-second-level li > a:hover, .metismenu .parent .nav-second-level li > a:active, .metismenu .parent .nav-second-level li > a:focus {
  background: #425065 !important;
}
.metismenu .parent .nav-second-level .dot-line {
  position: absolute;
  height: 100%;
  border: 1px solid white;
  top: 24px;
  left: 34px;
  z-index: 10;
  opacity: 0.7;
}
.metismenu .parent .nav-second-level li:last-child span.dot-line {
  display: none;
}
.metismenu .collapse.in {
  background-color: #1c3855;
}
.metismenu .nav-second-level li:last-child {
  margin-bottom: 0px;
}

.status {
  padding: 5px;
}
.status.status--1 {
  background: #fba152;
  color: white;
}
.status.status--2 {
  background: red;
  color: white;
}

.status--1 td {
  background-color: #f3f1f1;
}

.transactions table thead tr th {
  border-bottom: 2px solid #6a95ae;
  word-wrap: break-word;
}
.transactions table tbody tr td {
  border-top: 1px solid #d2dae0;
  word-wrap: break-word;
}
.transactions table tbody tr td.maxw {
  max-width: 200px;
}

.purchase-rules .block {
  padding: 50px;
}
.purchase-rules .block .title {
  font-size: 22px;
  padding-bottom: 25px;
}
.purchase-rules .right input {
  margin: 5px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  height: 70px;
}
.purchase-rules .info {
  margin-bottom: 25px;
}
.purchase-rules form .control-label {
  text-align: left !important;
}
.purchase-rules form .control-label span {
  font-weight: 100;
  display: block;
}
.purchase-rules form table td {
  vertical-align: middle;
  border-top: 0px;
}
.purchase-rules form table td input[type=radio] {
  margin-right: 10px;
}
.purchase-rules form table td input {
  text-align: center;
}
.purchase-rules form table td input.form-control {
  padding: 10px;
}
.purchase-rules form .input-mrg {
  padding-top: 33px;
}
.purchase-rules form .btn-bar button {
  margin: 10px;
}

.loyalty-list .head {
  margin-bottom: 25px;
}
.loyalty-list .purchase-title .title {
  font-weight: bolder;
  float: left;
  font-size: 18px;
}
.loyalty-list .purchase-title .icon {
  background: url("/static/img/purchase-rule.png") no-repeat;
  display: inline;
  background-position: center;
}
.loyalty-list .purchase-description {
  display: block;
  margin-top: 5px;
  font-weight: bold;
  padding-left: 5px;
}
.loyalty-list .btn-action a {
  float: right;
}
.loyalty-list .table {
  margin-bottom: 0px;
}
.loyalty-list .table .noresults img {
  margin-right: 5px;
}

.btn-sm {
  padding: 5px 30px;
}

.footer {
  background-color: #F3F3F4;
  color: #747a81;
  padding: 20px 10px 8px;
  z-index: 1000;
}

.footer a {
  color: #747a81;
}

@media screen and (max-width: 768px) {
  body.mini-navbar .nav-header {
    background: #1c3855;
    transition: 0;
  }
  .auth .tit {
    padding: 30px 0;
  }
  .auth .acc {
    font-size: 12px;
  }
  .auth .links .btn-reg {
    padding-left: 0px;
    padding-right: 0px;
  }
  .auth .footer-auth {
    height: inherit;
  }
  .auth .footer-auth span, .auth .footer-auth ul > li > a {
    line-height: 35px;
  }
  .widget-data {
    margin: 10px 0;
  }
  .widget-data .text {
    font-size: 15px;
  }
  .navbar-static-side ul .elem a {
    padding-left: 25px;
  }
  .metismenu .nav-second-level {
    background-color: #2c3c54 !important;
    padding: 0px;
  }
  .metismenu .nav-second-level li {
    padding-left: 15px;
  }
  .navbar-top-links.navbar-right {
    float: right;
  }
  .metismenu .parent .nav-second-level a {
    padding-left: inherit;
  }
  .nav-dash .dropdown-menu {
    margin-top: 23px;
  }
  .nav-dash .navbar {
    margin-left: 70px;
    padding: 18px 29px 18px 25px;
  }
  .nav-dash .dropdown-menu {
    margin-top: 47px;
    border-radius: 0;
    background-color: #1c3855;
    width: 300px;
    padding: 0;
    right: 0;
    left: auto;
  }
  .loyalty-list .btn-action {
    text-align: center;
    margin-top: 10px 0;
  }
  .loyalty-list .btn-action a {
    float: none;
  }
  .purchase-rules form .control-label {
    margin-bottom: 20px;
  }
  .profile {
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 425px) {
  .hide-icon-xs .ca-icon {
    display: none;
  }
  .widget-data {
    margin: 5px 0;
  }
  .widget-data .text {
    font-size: 14px;
  }
  .widget-data .ca-icon {
    display: none;
  }
  .well {
    border-radius: 0;
    padding: 30px;
  }
  .purchase-rules .block {
    padding: 25px !important;
  }
  .profile {
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 350px) {
  .nav.navbar-top-links li.dropdown {
    display: inherit;
  }
  .nav-dash .dropdown-menu {
    margin-top: 32px;
    width: 263px;
    zoom: 0.75;
  }
}
.edit_icon {
  display: block;
  height: 21px;
  width: 21px;
  background-repeat: no-repeat;
  background: url("/static/img/edit.png");
}

.edit_icon:hover {
  background: url("/static/img/edit-hover.png");
}

#first-rule-panel {
  -webkit-box-shadow: 0px 4px 41px -6px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 4px 41px -6px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 4px 41px -6px rgba(0, 0, 0, 0.57);
}

.cross-icon-button {
  cursor: pointer;
  display: block;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat !important;
  background: url("/static/img/cross.png");
  background-position: center center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.cross-icon-button:hover {
  background: url("/static/img/cross-hover.png");
}

#first-rule-topmsg {
  display: none;
}

.info-btn {
  background-repeat: no-repeat !important;
  background-position: center center;
  background: url("/static/img/info.png");
  width: 22px;
  height: 22px;
  cursor: pointer;
}

#purchases-info {
  position: absolute;
  position: absolute;
  right: -20px;
  top: 10px;
}

.tooltip-inner {
  background-color: #036 !important;
  border-radius: 0px !important;
}

.tooltip.top .tooltip-arrow {
  border-top: 5px solid #036 !important;
}

.tooltip.left .tooltip-arrow {
  border-left: 5px solid #036 !important;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom: 5px solid #036 !important;
}

.tooltip.right .tooltip-arrow {
  border-right: 5px solid #036 !important;
}

.tableform .textformtable {
  padding-left: 10px;
}

#seetypes {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.loyalty-list .purchase-title .activityicon {
  background: url("/static/img/activity-rule.png") no-repeat !important;
}

.row.filters {
  padding-bottom: 10px;
}

.activity_identifier {
  font-weight: bold;
}

[ng-click], [ui-sref], [data-toggle=modal], [ngf-select], [ca-show-confirm], [onclick] {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .profile {
    padding-bottom: 100px;
  }
}
.well {
  padding-left: 60px;
  padding-right: 60px;
}

.modal-backdrop {
  position: fixed;
}

.ca-well {
  background-color: white;
}

.ca-text-primary {
  color: #27547d !important;
}

.ca-text-regular {
  font-weight: 100 !important;
}

.dropdown-menu {
  border-radius: 0;
}

.row.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

pre {
  border-radius: 0;
  margin-bottom: 0;
}

label {
  font-weight: normal !important;
}
label .label-subtitle {
  text-transform: none !important;
}

.v-h-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

hr {
  border-top: 1px solid rgba(76, 112, 147, 0.3);
}

a:focus {
  outline: none !important;
}