#gratitude-screen .modal-content {
  min-width: 720px;
}
#gratitude-screen .modal-content .centered {
  text-align: center;
  font-size: 0;
}
#gratitude-screen .modal-content .centered > div {
  float: none;
  display: inline-block;
  text-align: left;
  font-size: 13px;
}