.col-label {
  padding-left: 0;
  padding-right: 0;
  width: 140px;
}
.col-label span {
  font-size: 13px;
  margin-top: 8px;
  display: inline-block;
}

.inline-label {
  font-size: 13px;
  margin-top: 8px;
  display: inline-block;
}

.selling-partners tr, .selling-partners td, .selling-partners th {
  border: 1px solid #C6D5DF;
}
.selling-partners td, .selling-partners th {
  height: 59px;
  vertical-align: middle;
}
.selling-partners td {
  vertical-align: middle !important;
  padding: 10px !important;
}
.selling-partners .template-selector-cell {
  width: 40%;
  border-left: none;
  padding-top: 15px !important;
}
.selling-partners .template-selector-cell .control {
  display: inline-block;
}
.selling-partners .template-content {
  vertical-align: top !important;
}
.selling-partners .control-label {
  margin-top: 11px;
}
.selling-partners .control-label.text-right {
  text-align: right;
}
.selling-partners .fill-cell {
  height: auto;
  padding: 0 !important;
}
.selling-partners .label-between {
  margin-left: -15px;
  margin-right: -20px;
}
.selling-partners .template-active {
  box-shadow: 3px 0 0 #00beaf;
}
.selling-partners [ng-model="vm.selectedTemplate.name"] {
  border: none;
}
.selling-partners [ng-model="vm.selectedTemplate.name"]:focus {
  outline: none;
}
.selling-partners .create-link img {
  margin-top: -3px;
}
.selling-partners .create-link span {
  display: inline-block;
  margin-left: 10px;
}

.horizontal-form-row {
  display: flex;
  flex-direction: row;
}
.horizontal-form-row span {
  margin-top: 27px;
  margin-left: 20px;
}
.horizontal-form-row span .delete-icon {
  width: 17px;
}
.horizontal-form-row span .delete-icon path {
  color: red;
}
.horizontal-form-row input {
  margin-top: 18px;
  margin-left: 20px;
}
.horizontal-form-row input.ca-units-input {
  width: 13%;
}

p.top-label {
  font-size: 12px;
  margin-bottom: 0;
  left: 35px;
  margin-top: -17px;
  position: relative;
}