#currency-form .modal-content {
  background-color: #FFFFFF;
  padding-bottom: 0;
  min-width: 970px;
}
#currency-form .modal-header {
  background-color: #EFEFF0;
  color: #60819D;
}
#currency-form .modal-body {
  background-color: #FFFFFF;
}
#currency-form .note {
  color: #9EB0AF !important;
}
#currency-form .help-block {
  color: #9EB0AF !important;
}
#currency-form label {
  color: #003366;
}
#currency-form [id-prop] .btn-group .btn {
  text-align: left;
  width: 100%;
  height: 47px;
  padding: 10px 10px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c6d6df;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
#currency-form [id-prop] .btn-group .btn .caret {
  display: none;
}
#currency-form .dropdown-menu {
  border-radius: 5px;
  height: 500px;
}
#currency-form .dropdown-menu > li > a {
  color: #27547D !important;
}
#currency-form .dropdown-menu > .active > a, #currency-form .dropdown-menu > .active > a:focus, #currency-form .dropdown-menu > .active > a:hover {
  color: #FFF !important;
  position: relative;
  top: 0;
}
#currency-form .ca-no-error {
  border-color: #c6d6df !important;
}
#currency-form .ca-text-secondary {
  font-weight: 100;
  font-size: 0.8em;
  color: #9eb0af;
}